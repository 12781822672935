import { useEffect, useState } from "react";
import { Switch } from "../../components/Switch";
import { authService } from "../../services/authService";

export function Plans({ }) {

    const [plan, setPlan] = useState(authService.getPlan())
    const [isLoad, setIsLoad] = useState(false);
    const [isYearly, setIsYearly] = useState(false);

    const PLAN_TYPES_MAP = {
        free: {
            name: "free",
            features: [
                'Up to 30 orders per month',
                'UTM tracking',
                'Analytics & Statistics',
                'Date filter',
                'UTM link generator'
            ]
        },
        standard: {
            name: "standard",
            features: [
                'Up to 150 orders per month',
                'UTM tracking',
                'Analytics & Statistics',
                'Date filter',
                'UTM link generator'
            ]
        },
        pro: {
            name: "pro",
            features: [
                'Up to 500 orders per month',
                'UTM tracking',
                'Analytics & Statistics',
                'Date filter',
                'UTM link generator'
            ]
        },
        premium: {
            name: "premium",
            features: [
                'Unlimited orders per month',
                'UTM tracking',
                'Analytics & Statistics',
                'Date filter',
                'UTM link generator'
            ]
        },
    }

    const monthlyPlans = [
        {
            id: -1,
            price: 0,
            plan: PLAN_TYPES_MAP.free
        },
        {
            id: 1,
            price: 3.95,
            plan: PLAN_TYPES_MAP.standard
        },
        {
            id: 5,
            price: 11.95,
            plan: PLAN_TYPES_MAP.pro
        },
        {
            id: 3,
            price: 19.95,
            plan: PLAN_TYPES_MAP.premium
        }
    ]

    const yearlyPlans = [
        {
            id: -1,
            price: 0,
            plan: PLAN_TYPES_MAP.free
        },
        {
            id: 2,
            price: 39.5,
            plan: PLAN_TYPES_MAP.standard
        },
        {
            id: 6,
            price: 119.5,
            plan: PLAN_TYPES_MAP.pro
        },
        {
            id: 4,
            price: 199.5,
            plan: PLAN_TYPES_MAP.premium
        }
    ]





    useEffect(() => {
        updatePlan()
    }, [])

    const updatePlan = async () => {
        const currPlan = await authService.checkPlan()
        setPlan(currPlan)
        // if (currPlan === 1 || currPlan === 2) setPlan(1)
        // if (currPlan === 3 || currPlan === 4) setPlan(2)
    }

    const onBuy = async (planId) => {
        setIsLoad(true);
        const res = await authService.updatePlan(planId)
        if (res.link) window.location.href = res.link
        setIsLoad(false);
    }

    return (
        <div className="main-container" >
            <div className="plans-page page" >
                <div className="oversight-container">
                    <div className="pay-time-container" >
                        <span className={`${isYearly ? '' : 'monthly'}`} >Monthly</span>
                        <Switch
                            isCheck={isYearly}
                            toggleIsCheck={() => setIsYearly(prev => !prev)}
                        />
                        <span className={`${isYearly ? 'yearly' : ''}`} >Yearly (Save 16%)</span>
                    </div>
                    <div className="plans-container">
                        {
                            (isYearly ? yearlyPlans : monthlyPlans).map(planData => (
                                <div
                                    key={planData.id}
                                    className={`
                                    plan
                                    ${planData.plan.name} 
                                    ${planData.plan.name === 'standard' ? 'most-popular' : ''} 
                                    ${plan === planData.id ? 'curr' : ''}
                                    `}
                                >
                                    <span className={`title ${planData.plan.name === 'free' ? 'free' : ''}`}>{planData.plan.name}</span>
                                    {
                                        planData.plan.name !== 'free' && (
                                            <span className="price-mo"> <span className="price" >${planData.price}</span>{isYearly ? '/yr' : '/mo'} </span>
                                        )
                                    }
                                    <button
                                        onClick={() => { onBuy(planData.id) }}
                                        className="plan-btn"
                                    >
                                        {plan > planData.id && 'Downgrade '}
                                        {plan < planData.id && 'Upgrade '}
                                        {plan === planData.id && 'Your Current '}
                                        Plan
                                    </button>

                                    <div className="features flex column gap-0-5">
                                        {
                                            planData.plan.features.map(feature => (
                                                <span className="feature" >{feature}</span>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                        {/* {
                            Object.keys(PLAN_TYPES_MAP).map(key => (
                                <div
                                    key={key}
                                    className={`
                                    plan
                                    ${key} 
                                    ${key === 'standard' ? 'most-popular' : ''} 
                                    ${plan === PLAN_TYPES_MAP[key].id ? 'curr' : ''}
                                    `}
                                >
                                    <span className={`title ${key === 'free' ? 'free' : ''}`}>{key}</span>
                                    {
                                        key !== 'free' && (

                                            <>
                                                {
                                                    isYearly ?
                                                        <span className="price-mo"> <span className="price" >${PLAN_TYPES_MAP[key].yealyPrice}</span>/yr</span>
                                                        :
                                                        <span className="price-mo"> <span className="price" >${PLAN_TYPES_MAP[key].price}</span>/mo</span>
                                                }
                                            </>
                                        )
                                    }
                                    <button
                                        onClick={() => { onBuy(key) }}
                                        className="plan-btn"
                                    >
                                        {plan > PLAN_TYPES_MAP[key].id && 'Downgrade '}
                                        {plan < PLAN_TYPES_MAP[key].id && 'Upgrade '}
                                        {plan === PLAN_TYPES_MAP[key].id && 'Your Current '}
                                        Plan
                                    </button>

                                    <div className="features flex column gap-0-5">
                                        {
                                            PLAN_TYPES_MAP[key].features.map(feature => (
                                                <span className="feature" >{feature}</span>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        } */}
                    </div>
                </div>
            </div>
        </div>
    )
}