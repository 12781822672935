import { useId } from "react"

export function Input({
    value,
    onChange,
    placeholder = 'placeholder',
    type = 'text',
    style = 1,
    isDisabled = false
}) {

    const id = useId();

    return (
        <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
            <input
                name={id}
                id={id}
                type={type}
                required
                value={value}
                onChange={({ target }) => onChange(target.value)}
                className={`${isDisabled ? 'disabled' : ''}`}
                disabled={isDisabled}
            />
            <label htmlFor={id} >{placeholder}</label>
        </div>
    )
}