import moment from "moment-timezone";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import OversightIcons from "../../components/OversightIcons";
import { authService } from "../../services/authService";

export function Orders({ orderData, setOrderData, selectedTimezone }) {
	const resizesRef = useRef(null);
	const navigate = useNavigate();
	const shop = authService.getShop();
	if (!orderData) navigate("/");

	const createResizableColumn = function (col, resizer) {
		// Track the current position of mouse
		let x = 0;
		let w = 0;

		const mouseDownHandler = function (e) {
			// Get the current mouse position
			x = e.clientX;

			// Calculate the current width of column
			const styles = window.getComputedStyle(col);
			w = parseInt(styles.width, 10);

			// Attach listeners for document's events
			document.addEventListener("mousemove", mouseMoveHandler);
			document.addEventListener("mouseup", mouseUpHandler);
			resizer.classList.add("resizing");
		};

		const mouseMoveHandler = function (e) {
			// Determine how far the mouse has been moved
			const dx = e.clientX - x;

			// Update the width of column
			col.style.width = `${w + dx}px`;
		};

		// When user releases the mouse, remove the existing event listeners
		const mouseUpHandler = function () {
			document.removeEventListener("mousemove", mouseMoveHandler);
			document.removeEventListener("mouseup", mouseUpHandler);
			resizer.classList.remove("resizing");
		};

		resizer.addEventListener("mousedown", mouseDownHandler);
	};

	const resizeControl = (element) => {
		if (!element) return;
		if (resizesRef.current) {
			resizesRef.current.forEach((resizer) => {
				resizer.style.height = `${element.offsetHeight}px`;
			});
			return;
		}
		const cols = element.querySelectorAll("th");
		[].forEach.call(cols, function (col) {
			// Create a resizer element
			const resizer = document.createElement("div");
			resizer.classList.add("resizer");
			if (!resizesRef.current) resizesRef.current = [resizer];
			else resizesRef.current.push(resizer);

			// Set the height
			resizer.style.height = `${element.offsetHeight}px`;

			// Add a resizer element to the column
			col.appendChild(resizer);

			// Will be implemented in the next section
			createResizableColumn(col, resizer);
		});
	};

	useEffect(() => {
		return () => {
			setOrderData(null);
		};
	}, []);

	const campaign = orderData?.parent
		? orderData?.parent.campaign?.name
		: orderData?.campaign?.name;
	const medium = orderData?.parent
		? orderData?.parent.medium?.name
		: orderData?.medium?.name;
	const content = orderData?.content;

	if (!orderData) return null;
	return (
		<div className="main-container">
			<div className="order-page page flex column gap-1">
				<div>
					<button
						onClick={() => {
							navigate("/");
						}}
						className="back"
					>
						<OversightIcons icon="arrow-left" />
						<span>Back</span>
					</button>
				</div>
				<div className="orders-utm">
					<div className="container">
						<span>Campaign</span>
						<span>
							{!campaign || campaign === "_&_" ? "-" : campaign}
						</span>
					</div>
					<div className="container">
						<span>Medium</span>
						<span>
							{!medium || medium === "_&_" ? "-" : medium}
						</span>
					</div>
					<div className="container">
						<span>Content</span>
						<span>
							{!content || content === "_&_" ? "-" : content}
						</span>
					</div>
				</div>
				<div className="table-container">
					<table ref={resizeControl} className="table">
						<thead>
							<tr>
								<th>Order ID</th>
								<th>First name</th>
								<th>Last name</th>
								<th>Order date</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{orderData.orders.map((order) => {
								const orderNumber =
									order.id.split("/Order/")[1];
								const href = `https://${shop}/admin/orders/${orderNumber}`;

								// moment.tz.setDefault(selectedTimezone);
								// moment().tz("America/Los_Angeles").format();
								// const tz = selectedTimezone ?? "Asia/Jerusalem";
								const tz = "Asia/Jerusalem";

								const date = new Date(order.date);
								// const nowMoment = moment.utc(date);

								const formatDate = moment(date)
									.tz(tz)
									.format("DD/MM/YY");

								return (
									<tr tr key={order.id}>
										<td>{order.name}</td>
										<td>{order.customer.firstName}</td>
										<td>{order.customer.lastName}</td>
										<td>{formatDate}</td>
										<td>
											<a href={href} target="_blank">
												<OversightIcons
													icon={"shopify"}
												/>
												View in Shopify
											</a>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
