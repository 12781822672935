import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox } from "../../components/Checkbox";
import { ContextMenu } from "../../components/ContextMenu";
import { Dropdown } from "../../components/Dropdown";
import { Input } from "../../components/Input";
import OversightIcons from "../../components/OversightIcons";
import { RangeDatesPicker } from "../../components/RangeDatesPicker";
import { Select } from "../../components/Select";
import { Spinner } from "../../components/Spinner";
import { Tooltip } from "../../components/Tooltip";
import { useContextMenu } from "../../hooks/useContextMenu";
import { useDimensions } from "../../hooks/useDimensions";
import { authService } from "../../services/authService";
import { utmService } from "../../services/utmService";
import * as XLSX from "xlsx";
import Modal from "../../components/Modal";
import TimezoneSelect, { allTimezones } from "react-timezone-select";

const columns = [
  "campaign",
  "medium",
  "content",
  "source",
  "term",
  "count",
  "total",
  "average",
  "actions",
  "campaign-total",
];
export function Home({
  rangeDates,
  setRangeDates,
  ordersByDates,
  isPlanLimit,
  isPlanLimitShow,
  setIsPlanLimitShow,
  limit,
  isLoad,
  updateOrdersAndLimit,
  intervalCount,
  isHaveUtms,
  currency,
  isError,
  setOrderData,
  selectedTimezone,
  setSelectedTimezone,
  shopTimeZone,
}) {
  const [utms, setUtms] = useState([]);
  const [utms2, setUtms2] = useState([]);
  const [filterUtms, setFilterUtms] = useState([]);
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const [isExplanOpen, setIsExplaneOpen] = useState(true);
  const resizesRef = useRef(null);
  const [selectedOptions, setSelectedOptions] = useState(columns);
  const [orderIds, setOrderIds] = useState([]);
  const shop = authService.getShop();
  const { contextMenuPosition, isShowContextMenu, contextMenuHandler } =
    useContextMenu();

  const createResizableColumn = function (col, resizer) {
    // Track the current position of mouse
    let x = 0;
    let w = 0;

    const mouseDownHandler = function (e) {
      // Get the current mouse position
      x = e.clientX;

      // Calculate the current width of column
      const styles = window.getComputedStyle(col);
      w = parseInt(styles.width, 10);

      // Attach listeners for document's events
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
      resizer.classList.add("resizing");
    };

    const mouseMoveHandler = function (e) {
      // Determine how far the mouse has been moved
      const dx = e.clientX - x;

      // Update the width of column
      col.style.width = `${w + dx}px`;
    };

    // When user releases the mouse, remove the existing event listeners
    const mouseUpHandler = function () {
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
      resizer.classList.remove("resizing");
    };

    resizer.addEventListener("mousedown", mouseDownHandler);
  };

  const resizeControl = (element) => {
    if (!element) return;
    if (resizesRef.current) {
      resizesRef.current.forEach((resizer) => {
        resizer.style.height = `${element.offsetHeight}px`;
      });
      return;
    }
    const cols = element.querySelectorAll("th");
    [].forEach.call(cols, function (col) {
      // Create a resizer element
      const resizer = document.createElement("div");
      resizer.classList.add("resizer");
      if (!resizesRef.current) resizesRef.current = [resizer];
      else resizesRef.current.push(resizer);

      // Set the height
      if (element) resizer.style.height = `${element.offsetHeight}px`;

      // Add a resizer element to the column
      col.appendChild(resizer);

      // Will be implemented in the next section
      createResizableColumn(col, resizer);
    });
  };

  const onExport = () => {
    const filename = "reports.xlsx";

    const data = utms.map((utm) => {
      return {
        campaign: utm.campaign || "",
        medium: utm.medium || "",
        content: utm.content || "",
        source: utm.source || "",
        term: utm.term || "",
        count: utm.count || "",
        total: utm.total || "",
        average: utm.average || "",
      };
    });
    console.table(data);
    const orderedData = data.sort((utm1, utm2) => {
      if (utm1.campaign !== utm2.campaign)
        return utm1.campaign < utm2.campaign ? -1 : 1;
      if (utm1.medium !== utm2.medium)
        return utm1.medium < utm2.medium ? -1 : 1;
      if (utm1.content !== utm2.content)
        return utm1.content < utm2.content ? -1 : 1;
      if (utm1.source !== utm2.source)
        return utm1.source < utm2.source ? -1 : 1;
      if (utm1.term !== utm2.term) return utm1.term < utm2.term ? -1 : 1;
      return 0;
    });
    // const data = [{ Market: "IN", "New Arrivals": "6", "Upcoming Appointments": "2", "Pending - 1st Attempt": "4" },
    // { "Market": "KS/MO", "New Arrivals": "4", "Upcoming Appointments": "4", "Pending - 1st Attempt": "2" },
    // { Market: "KS/MO", "New Arrivals": "4", "Upcoming Appointments": "4", "Pending - 1st Attempt": "2" },
    // { Market: "KS/MO", "New Arrivals": "4", "Upcoming Appointments": "4", "Pending - 1st Attempt": "2" }]
    var ws = XLSX.utils.json_to_sheet(orderedData);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "UTMS");
    XLSX.writeFile(wb, filename);
  };

  useEffect(() => {
    const data = utmService.tableData(ordersByDates);
    setUtms(data);
  }, [rangeDates, ordersByDates]);

  useEffect(() => {
    setUtms2(utmService.tableData2(filterUtms));
  }, [filterUtms]);

  useEffect(() => {
    setFilterUtms(
      utms.filter(
        (utm) =>
          utm.source?.toLowerCase().includes(query.toLowerCase()) ||
          utm.campaign?.toLowerCase().includes(query.toLowerCase()) ||
          utm.medium?.toLowerCase().includes(query.toLowerCase()) ||
          utm.content?.toLowerCase().includes(query.toLowerCase()) ||
          utm.source?.toLowerCase().includes(query.toLowerCase()) ||
          utm.term?.toLowerCase().includes(query.toLowerCase())
      )
    );
  }, [utms, query]);

  const tableRows = Object.keys(utms2).reduce((accTableData, campaign) => {
    const campainObj = utms2[campaign];
    console.log({ campainObj });
    Object.keys(campainObj).forEach((medium, mediumIdx) => {
      const mediumObj = campainObj[medium];
      console.log({ medium });

      Object.keys(mediumObj).forEach((content, contentIdx) => {
        // const mediumObj = campainObj[medium];
        const contentArr = mediumObj[content];
        console.log({ contentArr });
        let campainRowData = null;
        let mediumRowData = null;
        const campainRows = Object.values(campainObj).reduce(
          (acc, mediumReducerObj) => {
            Object.values(mediumReducerObj).forEach((contentArr) => {
              acc++;
            });
            return acc;
          },
          0
        );
        console.log({ mediumObj });
        if (mediumIdx === 0 && contentIdx === 0) {
          campainRowData = {
            name: campaign,
            rows: campainRows,
          };
        }

        const mediumRows = Object.values(mediumObj).length;
        if (contentIdx === 0) {
          mediumRowData = {
            name: medium,
            rows: mediumRows,
          };
        }

        const countRowData = contentArr.reduce((acc, item) => {
          return (acc += item.count);
        }, 0);

        const totalRowData = contentArr.reduce((acc, item) => {
          return (acc += item.total);
        }, 0);

        const allRowOrderData = contentArr.reduce((acc, item) => {
          return acc.concat(item.orderData);
        }, []);

        console.log({ allRowOrderData });

        const sourceData = contentArr.reduce((acc, item) => {
          if (!acc[item.source]) {
            acc[item.source] = {
              count: item.count,
              total: item.total,
            };
          } else
            acc[item.source] = {
              count: acc[item.source].count + item.count,
              total: acc[item.source].count + item.total,
            };
          return acc;
        }, {});

        const averageRowData = totalRowData / countRowData;

        console.log({ contentArr: contentArr[0], averageRowData });

        const row = {
          campaign: campainRowData,
          medium: mediumRowData,
          orderData: allRowOrderData,
          content: content,
          source: contentArr[0]?.source || undefined,
          term: contentArr[0]?.term || undefined,
          count: countRowData,
          total: Number(totalRowData).toLocaleString("he-IL", {
            maximumFractionDigits: 2,
          }),
          average: Number(averageRowData).toLocaleString("he-IL", {
            maximumFractionDigits: 2,
          }),
          sourceData,
          parent: {
            medium: {
              name: medium,
              rows: mediumRows,
            },
            campaign: {
              name: campaign,
              rows: campainRows,
            },
          },
        };
        accTableData.push(row);
      });
    });
    return accTableData;
  }, []);

  const { count, total } = utms.reduce(
    (acc, utm) => {
      acc.count += utm.count;
      acc.total += +utm.total;
      return acc;
    },
    { count: 0, total: 0 }
  );

  const onMouseEnter = (e) => {
    if (!e.target.childNodes) return;
    const elText = e.target.childNodes?.[1];
    if (!elText) return;

    const elTooltip = e.target.childNodes[0];
    if (elText?.offsetWidth < elText?.scrollWidth && !elTooltip.classList[1]) {
      elTooltip.classList.add("show");
    } else if (
      !(elText?.offsetWidth < elText?.scrollWidth) &&
      elTooltip.classList[1]
    ) {
      elTooltip.classList.remove("show");
    }
    // if (e.target.offsetWidth < e.target.scrollWidth && !e.target.title) e.target.title = e.target.innerText
  };

  const handleChangeTz = (tz) => {
    setSelectedTimezone(tz);
    localStorage.setItem("timezone", tz.value);
  };

  return (
    <div className="main-container">
      <div className="home-page page">
        <div className="flex column gap-1">
          {isExplanOpen && (
            <div className="explan flex align-center">
              <div>
                <OversightIcons icon={"info"} />
              </div>
              <span className="flex column">
                <span>
                  SenseApps UTM is an application that helps you analyze your
                  UTMs info, from which UTM you get more purchases.
                </span>
                <span>
                  You can see it by the platform/campaign/ad set/the creative
                  itself! If you need help creating a UTM, you can go to our{" "}
                  <Link to={"/generation"}>UTM Link Generator</Link> and start
                  using it to better understand your store analytics.
                </span>
              </span>
              <button
                onClick={() => {
                  setIsExplaneOpen(false);
                }}
                className="close"
              >
                ✕
              </button>
            </div>
          )}
          <div className="totals-utm">
            <div className="container">
              <div className="flex align-center gap-0-5 justify-center">
                <div>Total UTM orders value</div>
                <Tooltip>
                  The total cart value of orders generated from UTM, based on
                  the specified dates range
                </Tooltip>
              </div>

              {total > 0 && (
                <span>
                  {currency}
                  {Number(total).toLocaleString("he-IL", {
                    maximumFractionDigits: 2,
                  })}
                </span>
              )}
              {!total && <span>-</span>}
            </div>
            <div className="container">
              <div className="flex align-center gap-0-5 justify-center">
                <div>Total UTM orders count</div>
                <Tooltip>
                  The total amount of orders generated from UTM, based on the
                  specified dates range
                </Tooltip>
              </div>
              {count > 0 && (
                <span>
                  {count.toLocaleString("he-IL", {
                    maximumFractionDigits: 2,
                  })}
                </span>
              )}
              {!count && <span>-</span>}
            </div>
            <div className="container">
              <div className="flex align-center gap-0-5 justify-center">
                <div>Average UTM orders value</div>
                <Tooltip>
                  The average cart value of orders generated from UTM, based on
                  the specified dates range
                </Tooltip>
              </div>
              {total > 0 && count > 0 && (
                <span>
                  {currency}
                  {Number(total / count).toLocaleString("he-IL", {
                    maximumFractionDigits: 2,
                  })}
                </span>
              )}
              {!total && !count && <span>-</span>}
            </div>
          </div>
          <div className="flex gap-1 justify-space-between align-center filters">
            <RangeDatesPicker
              dates={rangeDates}
              setDates={setRangeDates}
              updateOrdersAndLimit={updateOrdersAndLimit}
              isLoad={isLoad}
            />

            <div className="input-container flex gap-1">
              <TimezoneSelect
                value={selectedTimezone}
                onChange={handleChangeTz}
                displayValue="UTC"
                classNames={{
                  control: () => "timezone-select",
                  option: () => "timezone-option",
                  container: () => "timezone-container",
                  dropdownIndicator: () => "timezone-dropdownIndicator",
                }}
                timezones={{
                  shopTimeZone: "Shop Timezone",
                  ...allTimezones,
                }}
              />
            </div>

            {/* <Select
    onChange={(values) => { if (values.length > 2) setSelectedOptions(values.map(v => v.value)) }}
    placeholder="select columns"
    options={columnsOptions}
    isMultiSelect={true}
    value={columnsOptions.filter(opt => selectedOptions.some(v => v === opt.value))}
/> */}
            <div className="flex gap-1 align-end">
              <div className=" search-container">
                <div className="svg-container">
                  <OversightIcons icon="search" />
                </div>
                <Input value={query} onChange={setQuery} placeholder="Search" />
              </div>
              <Dropdown icon="table-columns" text="Columns">
                <div
                  className="flex column"
                  onClick={(e) => e.stopPropagation()}
                >
                  {columns.map((column) => (
                    <Checkbox
                      isCheck={selectedOptions.includes(column)}
                      toggleIsCheck={() => {
                        if (selectedOptions.includes(column)) {
                          setSelectedOptions(
                            selectedOptions.filter((opt) => opt !== column)
                          );
                        } else {
                          setSelectedOptions([...selectedOptions, column]);
                        }
                      }}
                    >
                      {column.replace("-", " ")}
                    </Checkbox>
                  ))}
                </div>
              </Dropdown>
              <button className="button flex export" onClick={onExport}>
                <OversightIcons icon={"excel"} />
                Export
              </button>
            </div>
          </div>

          <Modal
            title={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0.4rem",
                }}
              >
                <OversightIcons icon="warning" />
                Alert
              </div>
            }
            onClose={() => setIsPlanLimitShow(false)}
            show={isPlanLimitShow && limit}
          >
            <p>
              Please notice that your current plan is limited to {limit} orders
              per month, but your store has exceeded this limit.
            </p>
            <button
              onClick={() => {
                navigate("/plans");
              }}
              className="button primary"
            >
              Upgrade
            </button>
          </Modal>
          {/* {isPlanLimit && (
            <div className="limit-plan flex gap-1 align-center justify-space-between">
              <span>
                Please notice that your current plan is limited to {limit}{" "}
                orders, but your store has exceeded this limit.
              </span>
              <button
                onClick={() => {
                  navigate("/plans");
                }}
                className="button primary"
              >
                Upgrade
              </button>
            </div>
          )} */}

          <ContextMenu
            isShowContextMenu={isShowContextMenu}
            position={contextMenuPosition}
          >
            Orders:
            {orderIds.map((id) => {
              const orderNumber = id.split("/Order/")[1];
              const href = `https://${shop}/admin/orders/${orderNumber}`;
              return (
                <a className="menu-item" href={href} target="_blank">
                  {orderNumber}
                </a>
              );
            })}
          </ContextMenu>

          <div className="table-container">
            <table ref={resizeControl} className="table">
              <thead>
                <tr>
                  <th
                    className={`${
                      selectedOptions.includes("campaign") ? "" : "hide"
                    }`}
                  >
                    Campaign
                  </th>
                  <th
                    className={`${
                      selectedOptions.includes("medium") ? "" : "hide"
                    }`}
                  >
                    Medium
                  </th>
                  <th
                    className={`${
                      selectedOptions.includes("content") ? "" : "hide"
                    }`}
                  >
                    Content
                  </th>
                  <th
                    className={`${
                      selectedOptions.includes("source") ? "" : "hide"
                    }`}
                  >
                    Source
                  </th>
                  <th
                    className={`${
                      selectedOptions.includes("term") ? "" : "hide"
                    }`}
                  >
                    Term
                  </th>
                  <th
                    className={`${
                      selectedOptions.includes("count") ? "" : "hide"
                    }`}
                  >
                    Count
                  </th>
                  <th
                    className={`${
                      selectedOptions.includes("total") ? "" : "hide"
                    }`}
                  >
                    <div className="flex align-center gap-0-5 justify-center">
                      <div>Total</div>

                      <Tooltip>
                        The total value of purchases earned by UTM content
                      </Tooltip>
                    </div>
                  </th>
                  <th
                    className={`${
                      selectedOptions.includes("average") ? "" : "hide"
                    }`}
                  >
                    <div className="flex align-center gap-0-5 justify-center">
                      <div>Average</div>

                      <Tooltip>
                        The average cart value reached through UTM content
                      </Tooltip>
                    </div>
                  </th>
                  <th
                    className={`${
                      selectedOptions.includes("campaign-total") ? "" : "hide"
                    }`}
                  >
                    <div className="flex align-center gap-0-5 justify-center">
                      <div>Campaign Total</div>

                      <Tooltip>
                        The total value of purchases earned by UTM campaign
                      </Tooltip>
                    </div>
                  </th>
                  <th
                    className={`${
                      selectedOptions.includes("actions") ? "" : "hide"
                    }`}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              {isError && (
                <tbody className="error">
                  <tr>
                    <td colSpan={9}>
                      An error occured while trying to get data, please try
                      again.
                    </td>
                  </tr>
                </tbody>
              )}
              {!isError && isLoad && (
                <tbody>
                  <tr>
                    <td colSpan={9}>
                      <Spinner isShowText={intervalCount > 25} />
                    </td>
                  </tr>
                </tbody>
              )}
              {!isError &&
                !isLoad &&
                (tableRows.length ? (
                  <tbody>
                    {console.log({ tableRows })}
                    {tableRows.map((row, idx) => {
                      let total = 0;
                      let totalAvg = row?.average;

                      console.log({ row });

                      if (row.campaign?.rows > 1) {
                        console.log("checking campaign " + row.campaign?.name);
                        const sameCampaign = tableRows?.filter(
                          (r) => r.parent?.campaign?.name === row.campaign?.name
                        );

                        const totalCampaign = sameCampaign.reduce(
                          (acc, item) => {
                            const total = parseFloat(
                              item.total.replace(",", "")
                            );
                            return acc + total;
                          },
                          0
                        );

                        total = parseFloat(totalCampaign).toFixed(2);

                        const totalAvgCampaign = sameCampaign.reduce(
                          (acc, item) => {
                            const total = parseFloat(
                              item.average.replace(",", "")
                            );
                            return acc + total;
                          },
                          0
                        );

                        totalAvg = totalAvgCampaign / sameCampaign.length;
                      }

                      //   console.log(row);
                      return (
                        <tr key={idx}>
                          {row.campaign && (
                            <td
                              onMouseEnter={(ev) => onMouseEnter(ev)}
                              rowSpan={row.campaign.rows}
                              className={`${
                                selectedOptions.includes("campaign")
                                  ? ""
                                  : "hide"
                              }`}
                            >
                              <div className="table-tooltip">
                                {row.medium.name === "_&_"
                                  ? ""
                                  : row.campaign.name}
                              </div>
                              <span className="three-dots">
                                {row.campaign.name === "_&_"
                                  ? ""
                                  : row.campaign.name.replace("__&_", "")}
                              </span>
                            </td>
                          )}
                          {row.medium && (
                            <td
                              rowSpan={row.medium.rows}
                              onMouseEnter={(ev) => onMouseEnter(ev)}
                              className={`${
                                selectedOptions.includes("medium") ? "" : "hide"
                              }`}
                            >
                              <div className="table-tooltip">
                                {row.medium.name === "_&_"
                                  ? ""
                                  : row.medium.name}
                              </div>
                              <span className="three-dots">
                                {row.medium.name === "_&_"
                                  ? ""
                                  : row.medium.name}
                              </span>
                            </td>
                          )}

                          <td
                            onMouseEnter={(ev) => onMouseEnter(ev)}
                            className={`${
                              selectedOptions.includes("content") ? "" : "hide"
                            }`}
                          >
                            <div className="table-tooltip">
                              {row.content === "_&_" ? "" : row.content}
                            </div>
                            <span className="three-dots">
                              {row.content === "_&_" ? "" : row.content}
                            </span>
                          </td>
                          <td
                            onMouseEnter={(ev) => onMouseEnter(ev)}
                            className={`${
                              selectedOptions.includes("source") ? "" : "hide"
                            }`}
                          >
                            <div className="table-tooltip">
                              {row.source === "_&_" ? "" : row.source}
                            </div>
                            <span className="three-dots">
                              {row.source === "_&_" ? "" : row.source}
                            </span>
                          </td>

                          <td
                            onMouseEnter={(ev) => onMouseEnter(ev)}
                            className={`${
                              selectedOptions.includes("term") ? "" : "hide"
                            }`}
                          >
                            <div className="table-tooltip">
                              {!row.term ? "" : row.term}
                            </div>
                            <span className="three-dots">
                              {!row.term ? "" : row.term}
                            </span>
                          </td>
                          <td
                            className={`${
                              selectedOptions.includes("count") ? "" : "hide"
                            }`}
                          >
                            <div className="table-tooltip show">
                              {Object.keys(row.sourceData).map((key) => (
                                <span key={key}>
                                  {key}: {row.sourceData[key].count}
                                </span>
                              ))}
                            </div>
                            {row.count}
                          </td>
                          <td
                            className={`${
                              selectedOptions.includes("total") ? "" : "hide"
                            }`}
                          >
                            <div className="table-tooltip show">
                              {Object.keys(row.sourceData).map((key) => (
                                <span key={key}>
                                  {key}: {currency}
                                  {Number(
                                    row.sourceData[key].total
                                  ).toLocaleString("he-IL", {
                                    maximumFractionDigits: 2,
                                  })}
                                </span>
                              ))}
                            </div>
                            {currency}
                            {row.total}
                          </td>
                          <td
                            className={`${
                              selectedOptions.includes("average") ? "" : "hide"
                            }`}
                          >
                            <div className="table-tooltip show">
                              {Object.keys(row.sourceData).map((key) => (
                                <span key={key}>
                                  {key}: {currency}
                                  {Number(
                                    row.sourceData[key].total /
                                      row.sourceData[key].count
                                  ).toLocaleString("he-IL", {
                                    maximumFractionDigits: 2,
                                  })}
                                </span>
                              ))}
                            </div>
                            {currency}
                            {row.average}
                          </td>
                          {row.campaign && (
                            <td
                              className={`${
                                selectedOptions.includes("campaign-total")
                                  ? ""
                                  : "hide"
                              }`}
                              rowSpan={row.campaign?.rows}
                            >
                              <div className="table-tooltip show">
                                Average: {currency}
                                {Number(totalAvg).toLocaleString("he-IL", {
                                  maximumFractionDigits: 2,
                                })}
                              </div>
                              {total ? (
                                <>
                                  {currency}
                                  {Number(total).toLocaleString("he-IL", {
                                    maximumFractionDigits: 2,
                                  })}
                                </>
                              ) : (
                                <>
                                  {currency}
                                  {row.total}
                                </>
                              )}
                            </td>
                          )}
                          <td
                            className={`${
                              selectedOptions.includes("actions") ? "" : "hide"
                            }`}
                          >
                            <button
                              onClick={(ev) => {
                                setOrderData({
                                  ...row,
                                  orders: row.orderData,
                                });
                                navigate("/orders");
                              }}
                            >
                              <OversightIcons icon={"orders"} />
                              View orders
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={9}>
                        We didn't find any UTM orders between the filtered dates
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </div>

          {/* <Table
                            columnMap={{
                                source: 'Source',
                                campaign: 'Campaign',
                                medium: 'Medium',
                                content: 'Content',
                                count: 'Count',
                                total: 'Total',
                                average: 'Average',
                            }}
                            isHaveOptions={false}
                            isHavePaginator={true}
                            isLoad={isLoad || isDebaunceLoad}
                            metaData={filterUtms}
                            reorderColumns={[
                                'source', 'medium', 'campaign', 'content', 'count', 'total', 'average'
                            ]}
                            onRowClick={() => { }}
                        /> */}
        </div>
      </div>
    </div>
  );
}
