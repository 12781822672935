import "./assets/style/global.scss";
import "react-date-range/dist/theme/default.css";
import "react-date-range/dist/styles.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import AuthRoute from "./components/AuthRoute";
import { Home } from "./pages/Home/Home";
import { useLocation } from "react-router-dom";
import { useThemeColors } from "./hooks/useThemeColors";
import { useEffect, useRef, useState } from "react";
import { Navbar } from "./components/Navbar";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { Generation } from "./pages/Generation/Generation";
import { NoShop } from "./pages/NoShop/NoShop";
import { AuthGurad } from "./guard/AuthGurad";
import { Footer } from "./components/Footer";
import { Plans } from "./pages/Plans/Plans";
import { PlanGurad } from "./guard/PlanGuard";
import { utmService } from "./services/utmService";
import { authService, getOrdersNumberFromFeature } from "./services/authService";
import axios from "axios";
import { Instructions } from "./pages/Instructions/Instructions";
import { globalVariables } from "./services/globalVriables";
import { utilService } from "./services/utilService";
import { ToastrNotification } from "./components/ToastrNotification";
import { Orders } from "./pages/Orders/Orders";
import moment from "moment";
import { Apps } from "./pages/Apps/Apps";
import ReviewBox from "./components/ReviewBox";

function App() {
  const { getTheme, setTheme } = useThemeColors();
  const [allOrdersData, setAllOrdersData] = useState([]);
  const [isSend, setIsSet] = useState(false);
  const [ordersByDates, setOrdersByDates] = useState([]);

  const [isPlanLimit, setIsPlanLimit] = useState(false);
  const [isPlanLimitShow, setIsPlanLimitShow] = useState(!!isPlanLimit);

  const [limit, setLimit] = useState(null);
  const [isLoad, setIsLoad] = useState(false);
  const [isGet, setIsGet] = useState(false);
  const PAST_MS = 1000 * 60 * 60 * 24 * 1;
  const [intervalCount, setIntervalCount] = useState(0);
  const intervalRef = useRef(null);
  const [isHaveUtms, setIsHaveUtms] = useState(false);
  const [currency, setCurrency] = useState("$");
  const [isError, setIsError] = useState(false);
  const [orderData, setOrderData] = useState(null);

  useEffect(() => {
    setIsHaveUtms(allOrdersData.some((order) => order.utm));
  }, [allOrdersData]);

  // useSocket(onGetOrders);

  const [rangeDates, setRangeDates] = useState({
    startDate: utilService.startMonth(new Date()),
    endDate: utilService.endMonth(new Date()),
  });

  const startInterval = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(async () => {
      setIntervalCount((prev) => prev + 1);
      await checkStatus();
    }, 1 * 1000);
  };

  const getTimezone = async () => {
    try {
      const localTz = localStorage.getItem("timezone");
      if (localTz) {
        setSelectedTimezone(localTz);
      } else {
        const res = await utmService.getTimeZone();
        if (res) setSelectedTimezone(res);
        setShopTimeZone(res);
        console.log(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTimezone();
  }, []);

  const checkStatus = async () => {
    try {
      const res = await utmService.checkStatus();
      if (typeof res === "string") {
        const parseData = JSON.parse(res);
        setAllOrdersData(parseData.orders);
        setIsGet(true);
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        setIntervalCount(0);
        setIsPlanLimit(!parseData.isAll);
        setIsPlanLimitShow(!parseData.isAll);
        setTimeout(() => {
          setIsLoad(false);
        }, 500);
      }
    } catch(e) {
		console.log({ e })
      setIsError(true);
      setIsLoad(false);
      setIntervalCount(0);
      if (intervalRef.current) clearInterval(intervalRef.current);
      setIsGet(true);
    }
  };

  useEffect(() => {
    if (intervalCount === 90) {
      updateOrdersAndLimit();
    }
  }, [intervalCount]);

  useEffect(() => {
    globalVariables.setCurrency(currency);
  }, [currency]);

  const [selectedTimezone, setSelectedTimezone] = useState("null");
  const [shopTimeZone, setShopTimeZone] = useState(null);

  const updateOrdersAndLimit = async () => {
    try {
      if (
        !window.location.pathname.includes("no-shop") &&
        authService.getShop() &&
        authService.getToken()
      ) {
        setIsLoad(true);
        const res = await utmService.get(
          rangeDates,
          selectedTimezone.value || selectedTimezone
        );
        setCurrency(() => {
          switch (res.currencyCode) {
            case "ILS":
              return "₪";
            case "USD":
              return "$";
            default:
              return res.currencyCode;
          }
        });
        setIsSet(true);
        // if (+res.count === 0) {
        //   setIsLoad(false);
        //   setIsGet(true);
        // }
        console.log(res.bulkOperationId);
        if (!res.bulkOperationId) {
          console.log("no bulk");
          setAllOrdersData([]);
          setIsLoad(false);
          setIsGet(true);
        } else {
          console.log(intervalRef.current);
          if (!intervalRef.current) startInterval();
        }
        const plan = await authService.checkPlan();
        // setAllOrdersData(res.result);
        const currLimit = getOrdersNumberFromFeature(plan) || 30;
		console.log({ currLimit })
        // if (plan === 1 || plan === 2) currLimit = 150;
        // if (plan === 5 || plan === 6) currLimit = 500;
        // if (plan === 3 || plan === 4) currLimit = 0;
        setLimit(currLimit);
        console.log("plan", plan);
        // if (currLimit) setIsPlanLimit(res.count > currLimit);
      }
    } catch (e) {
		console.log({ e })
      setIsError(true);
      if (intervalRef.current) clearInterval(intervalRef.current);
      setIsLoad(false);
      setIsGet(true);
    }
  };

  useEffect(() => {
    setTheme();
    // updateOrdersAndLimit();
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    // const startDateTimestamp = rangeDates.startDate.setHours(0, 0, 0, 0);
    // const endDateTimestapm = rangeDates.endDate.setHours(23, 59, 59, 999);
    // const filterOrders = allOrdersData.filter((order) => {
    //   const dateTimestamp = new Date(order.createAt).getTime();
    //   return (
    //     dateTimestamp >= startDateTimestamp && dateTimestamp <= endDateTimestapm
    //   );
    // });
    setOrdersByDates(allOrdersData);
  }, [allOrdersData]);

  useEffect(() => {
    updateOrdersAndLimit();
  }, [rangeDates]);

  // http://localhost:3000/?shop=sense-apps-test2.myshopify.com

  return (
    <div className="App">
      {/* <PlanLimitModal show={true} setShow={() => {}} limit={limit} /> */}
      <ToastrNotification />
      <ReviewBox />
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<AuthRoute />} />
          <Route exact path="/no-shop" element={<NoShop />} />
          <Route
            exact
            path="/plans"
            element={
              <AuthGurad>
                <Plans />
              </AuthGurad>
            }
          />
          <Route
            exact
            path="/home"
            element={
              <AuthGurad>
                {/* <PlanGurad> */}
                <Home
                  rangeDates={rangeDates}
                  setRangeDates={setRangeDates}
                  ordersByDates={ordersByDates}
                  isPlanLimit={isPlanLimit}
                  isPlanLimitShow={isPlanLimitShow}
                  setIsPlanLimitShow={setIsPlanLimitShow}
                  limit={limit}
                  isLoad={isLoad}
                  updateOrdersAndLimit={updateOrdersAndLimit}
                  intervalCount={intervalCount}
                  isHaveUtms={isHaveUtms}
                  currency={currency}
                  isError={isError}
                  setOrderData={setOrderData}
                  selectedTimezone={selectedTimezone}
                  setSelectedTimezone={setSelectedTimezone}
                  shopTimeZone={shopTimeZone}
                />
                {/* </PlanGurad> */}
              </AuthGurad>
            }
          />
          <Route
            exact
            path="/dashboard"
            element={
              <AuthGurad>
                {/* <PlanGurad> */}
                <Dashboard
                  rangeDates={rangeDates}
                  setRangeDates={setRangeDates}
                  ordersByDates={ordersByDates}
                  isPlanLimit={isPlanLimit}
                  isPlanLimitShow={isPlanLimitShow}
                  setIsPlanLimitShow={setIsPlanLimitShow}
                  limit={limit}
                  isLoad={isLoad}
                  updateOrdersAndLimit={updateOrdersAndLimit}
                  intervalCount={intervalCount}
                  isHaveUtms={isHaveUtms}
                  currency={currency}
                  isError={isError}
                />
                {/* </PlanGurad> */}
              </AuthGurad>
            }
          />
          <Route
            exact
            path="/generation"
            element={
              <AuthGurad>
                {/* <PlanGurad> */}
                <Generation />
                {/* </PlanGurad> */}
              </AuthGurad>
            }
          />
          <Route
            exact
            path="/orders"
            element={
              <AuthGurad>
                {/* <PlanGurad> */}
                <Orders
                  setOrderData={setOrderData}
                  orderData={orderData}
                  selectedTimezone={selectedTimezone}
                />
                {/* </PlanGurad> */}
              </AuthGurad>
            }
          />
          <Route
            exact
            path="/instructions"
            element={
              <AuthGurad>
                {/* <PlanGurad> */}
                <Instructions />
                {/* </PlanGurad> */}
              </AuthGurad>
            }
          />
          <Route exact path="/apps" element={<Apps />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
