import { useEffect, useState } from "react";
import { Switch } from "../../components/Switch";
import { authService, usePlans } from "../../services/authService";
import BlackFridayBox from "./BlackFridayBox";

export function Plans() {
  const plans = usePlans();
  console.log({ plans });
  const [plan, setPlan] = useState(authService.getPlan());
  const [isLoading, setIsLoading] = useState(false);
  const [isYearly, setIsYearly] = useState(false);

  // const PLAN_TYPES_MAP = {
  //     free: {
  //         name: "free",
  //         features: [
  //             'Up to 30 orders per month',
  //             'UTM tracking',
  //             'Analytics & Statistics',
  //             'Date filter',
  //             'UTM link generator'
  //         ]
  //     },
  //     standard: {
  //         name: "standard",
  //         features: [
  //             'Up to 150 orders per month',
  //             'UTM tracking',
  //             'Analytics & Statistics',
  //             'Date filter',
  //             'UTM link generator'
  //         ]
  //     },
  //     pro: {
  //         name: "pro",
  //         features: [
  //             'Up to 500 orders per month',
  //             'UTM tracking',
  //             'Analytics & Statistics',
  //             'Date filter',
  //             'UTM link generator'
  //         ]
  //     },
  //     premium: {
  //         name: "premium",
  //         features: [
  //             'Unlimited orders per month',
  //             'UTM tracking',
  //             'Analytics & Statistics',
  //             'Date filter',
  //             'UTM link generator'
  //         ]
  //     },
  // }

  // const monthlyPlans = [
  //     {
  //         id: -1,
  //         price: 0,
  //         plan: PLAN_TYPES_MAP.free
  //     },
  //     {
  //         id: 1,
  //         price: 3.95,
  //         plan: PLAN_TYPES_MAP.standard
  //     },
  //     {
  //         id: 5,
  //         price: 11.95,
  //         plan: PLAN_TYPES_MAP.pro
  //     },
  //     {
  //         id: 3,
  //         price: 19.95,
  //         plan: PLAN_TYPES_MAP.premium
  //     }
  // ]

  // const yearlyPlans = [
  //     {
  //         id: -1,
  //         price: 0,
  //         plan: PLAN_TYPES_MAP.free
  //     },
  //     {
  //         id: 2,
  //         price: 39.5,
  //         plan: PLAN_TYPES_MAP.standard
  //     },
  //     {
  //         id: 6,
  //         price: 119.5,
  //         plan: PLAN_TYPES_MAP.pro
  //     },
  //     {
  //         id: 4,
  //         price: 199.5,
  //         plan: PLAN_TYPES_MAP.premium
  //     }
  // ]

  const mutate = async () => {
    const newPlan = await authService.checkPlan();
    setPlan(newPlan);
  };

  useEffect(() => {
    updatePlan();
  }, []);

  const updatePlan = async () => {
    const currPlan = await authService.checkPlan();
    setPlan(currPlan);
    // if (currPlan === 1 || currPlan === 2) setPlan(1)
    // if (currPlan === 3 || currPlan === 4) setPlan(2)
  };

  const onBuy = async (planId) => {
    setIsLoading(true);
    const res = await authService.updatePlan(planId);
    if (res === "free") {
      await mutate();
      setIsLoading(false);
    } else {
      window.location.href = res.link;
    }
    setIsLoading(false);
  };

  const isBlackFridayUser = plans?.some((p) => p.name.includes("Black Friday"));
  useEffect(() => {
    if (isBlackFridayUser) {
      setIsYearly(true);
    }
  }, [isBlackFridayUser]);

  return (
    <div className="main-container">
      <div className="plans-page page">
        <div className="oversight-container">
          <BlackFridayBox />
          <div className="pay-time-container">
            <span className={`${isYearly ? "" : "monthly"}`}>Monthly</span>
            <Switch
              isCheck={isYearly}
              toggleIsCheck={() => setIsYearly((prev) => !prev)}
            />
            <span className={`${isYearly ? "yearly" : ""}`}>
              Yearly (Save {isBlackFridayUser ? 25 : 16}%)
            </span>
          </div>
          <div className="plans-container">
            {plans
              .filter((p) =>
                isYearly
                  ? p.interval === "ANNUAL"
                  : p.interval === "EVERY_30_DAYS"
              )
              .map((p) => (
                <Plan
                  key={p.id}
                  {...p}
                  currPlan={plan.plan}
                  onBuy={onBuy}
                  isLoading={isLoading}
                />
              ))}
            {/* {
                            Object.keys(PLAN_TYPES_MAP).map(key => (
                                <div
                                    key={key}
                                    className={`
                                    plan
                                    ${key} 
                                    ${key === 'standard' ? 'most-popular' : ''} 
                                    ${plan === PLAN_TYPES_MAP[key].id ? 'curr' : ''}
                                    `}
                                >
                                    <span className={`title ${key === 'free' ? 'free' : ''}`}>{key}</span>
                                    {
                                        key !== 'free' && (

                                            <>
                                                {
                                                    isYearly ?
                                                        <span className="price-mo"> <span className="price" >${PLAN_TYPES_MAP[key].yealyPrice}</span>/yr</span>
                                                        :
                                                        <span className="price-mo"> <span className="price" >${PLAN_TYPES_MAP[key].price}</span>/mo</span>
                                                }
                                            </>
                                        )
                                    }
                                    <button
                                        onClick={() => { onBuy(key) }}
                                        className="plan-btn"
                                    >
                                        {plan > PLAN_TYPES_MAP[key].id && 'Downgrade '}
                                        {plan < PLAN_TYPES_MAP[key].id && 'Upgrade '}
                                        {plan === PLAN_TYPES_MAP[key].id && 'Your Current '}
                                        Plan
                                    </button>

                                    <div className="features flex column gap-0-5">
                                        {
                                            PLAN_TYPES_MAP[key].features.map(feature => (
                                                <span className="feature" >{feature}</span>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        } */}
          </div>
        </div>
      </div>
    </div>
  );
}

export const BLACK_FRIDAY = {
  "Black Friday Sale - Standard": 3.95,
  "Black Friday Sale - Pro": 11.95,
  "Black Friday Sale - Premium": 19.95,
};

const Plan = ({
  id,
  currPlan,
  name,
  total,
  amount,
  features,
  featuresOrder,
  interval,
  onBuy,
  isLoading,
  autoAppliedDiscount,
}) => {
  const isActive = currPlan && currPlan.id === id;

  const featuresWithOrder = featuresOrder.map((feature) => {
    return {
      ...features[feature],
    };
  });

  const featured = name === "Premium";
  const isYearly = interval === "ANNUAL";

  const isBlackFriday = name.includes("Black Friday");

  return (
    <div
      className={`
			plan ${isBlackFriday ? "black-friday" : ""}
			${name} 
			${featured ? "most-popular" : ""} 
			${isActive ? "curr" : ""}
			`}
    >
      <span className={`title ${name === "free" ? "free" : ""}`}>
        {name.replace("Black Friday Sale - ", "")}
      </span>
      <div
        style={{
          display: "flex",
          alignItems: "end",
        }}
      >
        {name !== "free" && (
          <span className="price-mo">
            {" "}
            <span className="price">${parseFloat(total).toFixed(2)}</span>
            {isYearly ? "/yr" : "/mo"}{" "}
          </span>
        )}
        {BLACK_FRIDAY[name] ? (
          <span
            style={{
              fontSize: 18,
              paddingLeft: 8,
              fontWeight: 700,
              opacity: 0.6,
              textDecoration: "line-through",
            }}
          >
            ${parseFloat(BLACK_FRIDAY[name] * 12).toFixed(2)}
          </span>
        ) : (
          total !== amount && (
            <span
              style={{
                fontSize: 18,
                paddingLeft: 8,
                fontWeight: 700,
                opacity: 0.6,
                textDecoration: "line-through",
              }}
            >
              ${parseFloat(amount).toFixed(2)}
            </span>
          )
        )}
      </div>
      {autoAppliedDiscount && (
        <div className="text-center">
          <b>Special Discount:</b> {autoAppliedDiscount.description}
        </div>
      )}
      <button
        onClick={() => {
          onBuy(id);
        }}
        className="plan-btn"
        disabled={isLoading}
        type="button"
      >
        {/* {currPlan > id && "Downgrade "}
				{currPlan < id && "Upgrade "} */}
        {isActive ? "Your Current Plan" : "Choose Plan"}
      </button>

      <div className="features flex column gap-0-5">
        {featuresWithOrder.map(
          (feature) =>
            feature.value && <span className="feature">{feature.name}</span>
        )}
      </div>
    </div>
  );
};
