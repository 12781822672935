import { authService } from "../services/authService"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react";

export function PlanGurad({ children }) {

    const [plan, setPlan] = useState(authService.getPlan());
    const navigate = useNavigate();

    useEffect(() => {
        if (plan === -1) onCkeckPlan();
    }, [plan])


    const onCkeckPlan = async () => {
        const afterCheckPlan = await authService.checkPlan();
        if (!afterCheckPlan || afterCheckPlan === -1) navigate('/plans')
        else setPlan(afterCheckPlan)
    }

    if (plan === -1) {
        return <div className="flex align-center justify-center loader-page">
            <div className='loader'><div></div><div></div><div></div><div></div></div>
        </div>
    }
    return children
}