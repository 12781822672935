import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import OversightIcons from "./OversightIcons";

const Modal = (props) => {
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  const handleClose = () => {
    props.onClose();
  };

  // Make sure modal only renders when it's visible
  if (!props.show) {
    return null;
  }

  return createPortal(
    <div
      className={`modal-backdrop ${props.show ? "show" : ""}`}
      onClick={handleClose} // Close when clicking on backdrop
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h4 className="modal-title">{props.title}</h4>
          <div className="close-modal" onClick={handleClose}>
            <OversightIcons icon="close" />
          </div>
        </div>
        <div className="modal-body">{props.children}</div>
      </div>
    </div>,
    document.getElementById("modal-root") // Ensure modal is rendered in a separate root
  );
};

export default Modal;
