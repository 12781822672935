import { authService } from "../services/authService"
import { useNavigate } from "react-router-dom"

export function AuthGurad({ children }) {

    const token = authService.getToken();
    const shop = authService.getShop();
    const navigate = useNavigate()

    if (!shop) navigate('/no-shop')
    if (!token) navigate('/')
    return children
}