import React, { useCallback, useEffect, useState } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";
import OversightIcons from "../../../components/OversightIcons";
import { useDimensions } from "../../../hooks/useDimensions";
import { useRect } from "../../../hooks/useRect";
import { globalVariables } from "../../../services/globalVriables";
import { utilService } from "../../../services/utilService";

const colors = [
  "#2980B9",
  "#1ABC9C",
  "#F1C40F",
  "#DC7633",
  "#DC7633",
  "#5D6D7E",
  "#5D6D7E",
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    count,
    primaryColor,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
  const newFill = payload.color || fill;
  const currency = globalVariables.getCurrency();
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={newFill}>
        {payload.source ?? "No Source"}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={newFill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={newFill}
      />
      {payload.source &&
        (payload.source !== "Loading" || payload.source !== "didn't send") && (
          <>
            <path
              d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
              stroke={newFill}
              fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={newFill} stroke="none" />
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey}
              textAnchor={textAnchor}
              fill="#0A3F16"
            >{`${count} Orders`}</text>
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey}
              dy={18}
              textAnchor={textAnchor}
              fill="#00000065"
            >
              {`(${(percent * 100).toFixed(2)}%)`}
            </text>
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey}
              dy={40}
              textAnchor={textAnchor}
              fill="#00000065"
            >
              {currency}
              {Number(payload.amount).toLocaleString("he-IL", {
                maximumFractionDigits: 2,
              })}
            </text>
          </>
        )}
    </g>
  );
};

export default function UTMPieChart({
  data,
  targetRef,
  primaryColor,
  isHaveUtms,
  currency,
  isError,
}) {
  const dimensions = useRect(targetRef);

  const colors = [
    primaryColor,
    "#2980B9",
    "#1ABC9C",
    "#F1C40F",
    "#DC7633",
    "#DC7633",
    "#5D6D7E",
    "#5D6D7E",
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      console.log("index", index);
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  useEffect(() => {}, [data]);

  if (data.length > 0)
    return (
      <PieChart width={dimensions?.width - 25} height={350}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          currency={currency}
          data={
            data.some((dataItem) => dataItem.count)
              ? data
              : [
                  {
                    name: "didn't send",
                    count: 1,
                    color: primaryColor,
                  },
                ]
          }
          cx={(dimensions?.width - 25) / 2}
          cy={175}
          innerRadius={70}
          outerRadius={90}
          fill={primaryColor}
          dataKey="count"
          colors={colors}
          data2={data}
          onMouseEnter={onPieEnter}
        />
      </PieChart>
    );
  else
    return (
      <div style={{ height: 350 }} className="placeholder">
        <OversightIcons icon="utm-pie-chart" />
        {isError && (
          <span className="error">
            An error occured while trying to get data, please try again.
          </span>
        )}
        {!isError && (
          <span>
            {isHaveUtms
              ? "We didn't find any UTM orders between the filtered dates"
              : "We didn't find any UTM orders on your shop"}{" "}
          </span>
        )}
      </div>
    );
}
