import httpService from "./httpService";

export const ratingService = {
  checkRating,
  updateRating,
};

async function checkRating() {
  try {
    const res = await httpService.get("rating");
    return res;
  } catch (error) {
    console.error(error);
    return false;
  }
}

async function updateRating(rating) {
  try {
    const res = await httpService.post("rating", { rating });
    return res;
  } catch (error) {
    console.error(error);
  }
}
