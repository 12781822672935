import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import useClickOutside from "../hooks/useClickOutside";
import { useThemeColors } from "../hooks/useThemeColors";
import { utilService } from "../services/utilService";
import OversightIcons from "./OversightIcons";

export function RangeDatesPicker({
  dates,
  setDates,
  updateOrdersAndLimit,
  isLoad,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useThemeColors();
  const pickerRef = useRef();

  const [state, setState] = useState({
    startDate: dates.startDate,
    endDate: dates.endDate,
    key: "dates",
  });

  useEffect(() => {
    // setDates({ startDate: state.startDate, endDate: state.endDate })
  }, [state]);

  useClickOutside(pickerRef, () => {
    if (isOpen) setIsOpen(false);
  });

  return (
    <div className="flex gap-1 date-picker-with-button">
      <div ref={pickerRef} className="date-picker-container">
        <div
          className="range-picker-toggle"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <OversightIcons icon="calendar" />
          <span>
            {utilService.formatDate(dates.startDate)} -{" "}
            {utilService.formatDate(dates.endDate)}
          </span>

          <button
            disabled={isLoad}
            onClick={(ev) => {
              ev.stopPropagation();
              updateOrdersAndLimit();
            }}
            className="refresh-button"
          >
            <OversightIcons icon="refresh" />
          </button>
        </div>
        <div className={`range-picker ${isOpen ? "open" : "close"}`}>
          <DateRangePicker
            color={theme.colorPrimary}
            onChange={({ dates }) =>
              setState({
                ...state,
                startDate: dates.startDate,
                endDate: dates.endDate,
              })
            }
            ranges={[state]}
            rangeColors={[theme.colorPrimary]}
          />
          <div className="flex justify-space-between">
            <div />

            <button
              disabled={
                state.startDate.toString() === dates.startDate.toString() &&
                state.endDate.toString() === dates.endDate.toString()
              }
              onClick={() => {
                setDates({
                  startDate: state.startDate,
                  endDate: state.endDate,
                });
                setIsOpen(false);
              }}
              className="primary button save-dates"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
