import { useEffect, useRef, useState } from "react";
import { toastrService } from "../services/toastrService";
import OversightIcons from "./OversightIcons";




export function ToastrNotification({ }) {
    const [notifications, setNotifications] = useState([]);
    const subscriber = useRef();

    useEffect(() => {
        subscriber.current = toastrService.notifications$.subscribe(notifications => {
            setNotifications(notifications);
        })

        return () => {
            subscriber.current.unsubscribe();
        }
    }, [])

    const notificationTypes = {
        0: 'none',
        1: 'error',
        2: 'info',
        3: 'warning',
        4: 'success',

    }


    return (
        <div className="notifications-container">
            {
                notifications.map((notification, idx) => (
                    <div
                        className={`notification ${notificationTypes[notification.type]}`}
                        key={idx}
                    >
                        <OversightIcons icon={notificationTypes[notification.type]} />
                        <div className="content" >
                            <span className="title" >
                                {notification.title}


                            </span>
                            <span className="text" >
                                {notification.text}
                            </span>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}