import httpService from "./httpService";
import moment from "moment";
import { toastrService } from "./toastrService";

export const utmService = {
  get,
  utmsByContent,
  utmsBySource,
  withUtmCount,
  utmsContentEarnByDates,
  tableData,
  tableData2,
  checkStatus,
  getTimeZone,
};

async function get(rangeDates, timeZone) {
  try {
    const startDate = moment(rangeDates.startDate).format("YYYY-MM-DD");
    const endDate = moment(rangeDates.endDate).format("YYYY-MM-DD");
    // return {
    //     result: utms.orders.map(order => {
    //         return {
    //             createAt: order.date.split('T')[0],
    //             totalPrice: utilService.getRandomDouble(1, 100),
    //             utm: utilService.getRandomDouble(0, 1) > 0.35 ? order : order
    //         }
    //     })
    // }
    return await httpService.get(
      "utm?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&timeZone=" +
        timeZone
    );
  } catch (e) {
	console.log({ e })
    toastrService.updateNotification({
      title: "Error",
      text: "An error occured while trying to get data, please try again.",
      type: 1,
    });
    throw new Error(
      "An error occured while trying to get data, please try again."
    );
  }
}

function utmsByContent(orders) {
  const colors = [
    "#2980B9",
    "#1ABC9C",
    "#F1C40F",
    "#DC7633",
    "#DC7633",
    "#5D6D7E",
    "#5D6D7E",
  ];
  return orders
    .reduce((acc, order) => {
      if (!order.utm || !order.utm.content) return acc;
      const index = acc.findIndex(
        (utm) =>
          // utm.medium === order.utm.medium &&
          utm.content === order.utm.content //&&
        // utm.campaign === order.utm.campaign &&
        // utm.source === order.utm.source
      );
      if (index !== -1) {
        const newAcc = [...acc];
        newAcc[index] = {
          ...newAcc[index],
          count: newAcc[index].count + 1,
          total: newAcc[index].total + +order.totalPrice,
        };
        return newAcc;
      } else {
        const newAcc = [
          ...acc,
          {
            medium: order.utm.medium,
            content: order.utm.content,
            campaign: order.utm.campaign,
            term: order.utm.term,
            source: order.utm.source,
            count: 1,
            total: +order.totalPrice,
          },
        ];
        return newAcc;
      }
    }, [])
    .map((item, idx) => ({
      ...item,
      color: colors[idx % 8],
      average: Number(+item.total / +item.count).toFixed(2),
    }));
}

function utmsBySource(orders) {
  return orders.reduce((acc, order) => {
    if (!order.utm) return acc;
    const index = acc.findIndex((utm) => utm.source === order.utm.source);
    if (index !== -1) {
      acc[index].count += 1;
      acc[index].amount += +order.totalPrice;
      return acc;
    } else
      acc = [
        ...acc,
        {
          source: order.utm.source,
          count: 1,
          amount: +order.totalPrice,
        },
      ];
    return acc;
  }, []);
}

function withUtmCount(orders) {
  return orders.reduce((acc, order) => {
    if (!order.utm) return acc;
    else return acc + 1;
  }, 0);
}

function utmsContentEarnByDates(orders, dates) {
  const days = [];
  let currDate = dates.startDate;
  while (
    currDate.toISOString().split("T")[0] !==
    dates.endDate.toISOString().split("T")[0]
  ) {
    days.push({ date: currDate.toISOString().split("T")[0] });
    currDate = new Date(currDate.getTime() + 1000 * 60 * 60 * 24);
  }
  const contents = orders
    .filter((order) => order.utm && order.utm.content)
    .map((order) => order.utm.content);

  const daysWithContents = days.map((day) => ({
    ...day,
    ...contents.reduce((acc, content) => {
      acc[content] = 0;
      return acc;
    }, {}),
  }));

  // const daysWithItems = orders.reduce((acc, order) => {
  //     if (!order.utm || !order.utm.content) return acc;
  //     const orderISOdate = order.createAt.split('T')[0];
  //     const dayIndex = days.findIndex(day => day.date === orderISOdate);

  //     const itemIndex = acc[dayIndex].items.findIndex(utm => (
  //         utm.medium === order.utm.medium &&
  //         utm.content === order.utm.content &&
  //         utm.campaign === order.utm.campaign &&
  //         utm.source === order.utm.source
  //     ))
  //     if (itemIndex !== -1) return acc[dayIndex].items[itemIndex].amount += (+order.totalPrice);
  //     else acc = [
  //         ...acc.map((day, idx) =>
  //             idx === dayIndex ?

  //                 {
  //                     ...day,
  //                     items: [
  //                         ...day.items,
  //                         {
  //                             medium: order.utm.medium,
  //                             content: order.utm.content,
  //                             campaign: order.utm.campaign,
  //                             source: order.utm.source,
  //                             amount: (+order.totalPrice)
  //                         }
  //                     ]
  //                 }
  //                 :
  //                 day
  //         )
  //     ]
  //     return acc
  // }, days)

  return orders.reduce((acc, order) => {
    if (!order.utm || !order.utm.content) return acc;
    const orderISOdate = order.createAt.split("T")[0];
    const dayIndex = acc.findIndex((day) => day.date === orderISOdate);
    if (dayIndex > -1) acc[dayIndex][order.utm.content] += +order.totalPrice;
    return acc;
  }, daysWithContents);
}

function tableData(orders) {
  const colors = [
    "#2980B9",
    "#1ABC9C",
    "#F1C40F",
    "#DC7633",
    "#DC7633",
    "#5D6D7E",
    "#5D6D7E",
  ];

  return orders
    .reduce((acc, order) => {
      if (!order.utm) return acc;
      const index = acc.findIndex(
        (utm) =>
          utm.medium === order.utm.medium &&
          utm.content === order.utm.content &&
          utm.campaign === order.utm.campaign &&
          utm.source === order.utm.source &&
          utm.term === order.utm.term
      );

      console.log({ index });

      const orderData = {
        id: order.id,
        name: order.name,
        customer: order.customer,
        date: order.createAt,
      };

      if (index !== -1) {
        const newAcc = [...acc];
        newAcc[index] = {
          ...newAcc[index],
          count: newAcc[index].count + 1,
          total: newAcc[index].total + +order.totalPrice,
          orderData: [...newAcc[index].orderData, orderData],
        };
        return newAcc;
      } else {
        const newAcc = [
          ...acc,
          {
            medium: order.utm.medium,
            content: order.utm.content,
            term: order.utm.term,
            campaign: order.utm.campaign,
            source: order.utm.source,
            count: 1,
            total: +Number(+order.totalPrice).toFixed(2),
            orderData: [orderData],
          },
        ];

        return newAcc;
      }
    }, [])
    .map((item, idx) => ({
      ...item,
      color: colors[idx % 8],
      average: +Number(+item.total / +item.count).toFixed(2),
    }));
}

function tableData2(bySource) {
  console.log({ bySource });

  let byCampaign = bySource.reduce((acc, source) => {
    const campaign = source.campaign ? source.campaign : "_&_";
    const term = source.term ? source.term : "_&_";

    const key = `${campaign}_${term}`; // Combine campaign and term to create a unique key

    if (acc[key]) acc[key].push(source);
    else acc[key] = [source];
    return acc;
  }, {});

  Object.keys(byCampaign).forEach((key) => {
    let byMedium = byCampaign[key].reduce((acc, source) => {
      const medium = source.medium ? source.medium : "_&_";
      if (acc[medium]) acc[medium].push(source);
      else acc[medium] = [source];
      return acc;
    }, {});

    Object.keys(byMedium).forEach((key2) => {
      const byContent = byMedium[key2].reduce((acc, source) => {
        const content = source.content ? source.content : "_&_";
        if (acc[content]) acc[content].push(source);
        else acc[content] = [source];
        return acc;
      }, {});

      byMedium[key2] = byContent;
    });

    byCampaign[key] = byMedium;
  });
  return byCampaign;
}

async function checkStatus() {
  try {
    return await httpService.get("utm/checkStatus");
  } catch (err) {
    toastrService.updateNotification({
      title: "Error",
      text: "An error occured while trying to get data, please try again.",
      type: 1,
    });
    throw new Error(
      "An error occured while trying to get data, please try again."
    );
  }
}

async function getTimeZone() {
  try {
    return await httpService.get("utm/timezone");
  } catch (err) {
    toastrService.updateNotification({
      title: "Error",
      text: "An error occured while trying to get data, please try again.",
      type: 1,
    });
    throw new Error(
      "An error occured while trying to get data, please try again."
    );
  }
}
