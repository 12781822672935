import React, { useState, useCallback, useRef } from "react";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
} from "recharts";
import OversightIcons from "../../../components/OversightIcons";
import { useDimensions } from "../../../hooks/useDimensions";
import { Item } from "./Item";

export function UTMAreaMultiLineChart({ data, primaryColor }) {
	const itemRefs = useRef([]);
	// const dimensions = useDimensions(targetRef)
	const colors = [
		primaryColor,
		"#2980B9",
		"#1ABC9C",
		"#F1C40F",
		"#DC7633",
		"#DC7633",
		"#5D6D7E",
		"#5D6D7E",
	];

	if (data.length > 0 && Object.keys(data[0]).length > 1) {
		const dataKeys = Object.keys(data[0]).filter((key) => key !== "date");
		const chartsLength = Math.ceil(Object.keys(data[0]).length / 5);
		const chartLines = Math.ceil(
			Object.keys(data[0]).length / chartsLength
		);

		return (
			<div className="areaMultiLine-container oversight-container">
				{[...Array(chartsLength).keys()].map((idx) => (
					<div
						key={idx}
						ref={(itemRef) =>
							(itemRefs.current = [...itemRefs.current, itemRef])
						}
						className=""
					>
						<Item
							data={data.map((dataItem) => {
								let newItem = {
									date: dataItem.date,
								};
								dataKeys
									.slice(
										idx * chartLines,
										(idx + 1) * chartLines
									)
									.forEach((key) => {
										newItem[key] = dataItem[key];
									});

								return newItem;
							})}
							primaryColor={primaryColor}
							targetRef={itemRefs.current[idx]}
						/>
					</div>
				))}
			</div>
		);

		// return (
		//     <div>
		//         <LineChart
		//             width={dimensions.width - 30}
		//             height={500}
		//             data={
		//                 data.map(item => {
		//                     const [year, month, day] = item.date.split('-')
		//                     return {
		//                         ...item,
		//                         date: `${month}/${day}`
		//                     }
		//                 })
		//             }
		//             margin={{
		//                 top: 5,
		//                 right: 30,
		//                 left: 20,
		//                 bottom: 5
		//             }}
		//         >
		//             {/* <CartesianGrid strokeDasharray="3 3" /> */}
		//             <XAxis dataKey="date" />
		//             <YAxis />
		//             <Tooltip
		//             />
		//             {/* <Legend
		//         /> */}

		//             {
		//                 Object.keys(data[0] || {})
		//                     .filter(key => key !== "date")
		//                     .map((key, idx) => (
		//                         <Line
		//                             key={idx}
		//                             type="monotone"
		//                             dataKey={key}
		//                             strokeOpacity={1}
		//                             stroke={colors[idx % 9]}
		//                             activeDot={{ r: 4 }}
		//                         />
		//                     ))
		//             }
		//         </LineChart>
		//     </div>

		// )
	} else
		return (
			<div style={{ height: 500 }} className="placeholder">
				<OversightIcons icon="utm-area-chart" />
				<span>
					We didn't find any UTM orders between the filtered dates
				</span>
			</div>
		);
}
