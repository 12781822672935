import { useId } from "react"

export function Checkbox({
    isCheck,
    toggleIsCheck,
    children,
    isDisabled = false,
    style = 1
}) {

    const id = useId()

    return (
        <div className='checkbox-container menu-item'>
            <input
                type="checkbox"
                id={id}
                checked={isCheck}
                onChange={() => toggleIsCheck()}
                disabled={isDisabled}
            />
            <label for={id}>{children}</label>
        </div>
    )
}