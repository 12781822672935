
export function useThemeColors() {

    const theme = {
        backgroundColor: '#f0f0f8',
        backgroundTableHeader: '#04AA6D',
        TableBorderColor: '#ddd',
        colorPrimary: '#fe7900',
        colorSecondary: '#242425',
        textDark: '#11171d',
        textDarkSecondary: '#7d888d',
        textLight: '#fff',
        smallBorderRadius: '4px',
        bigBorderRadius: '10px',
        lightBoxShadow: '0 1px 2px 0 #23394236',
        inputPadding: '0.5rem 1rem',
        labelHeight: '12px',
        footerHeight: '35px'

    }

    const setTheme = () => {
        document.documentElement.style.setProperty('--background-color', theme.backgroundColor);
        document.documentElement.style.setProperty('--background-table-header', theme.backgroundTableHeader);
        document.documentElement.style.setProperty('--table-border-color', theme.TableBorderColor);
        document.documentElement.style.setProperty('--color-primary', theme.colorPrimary);
        document.documentElement.style.setProperty('--color-secondary', theme.colorSecondary);
        document.documentElement.style.setProperty('--text-light', theme.textLight);
        document.documentElement.style.setProperty('--text-dark', theme.textDark);
        document.documentElement.style.setProperty('--text-dark-secondary', theme.textDarkSecondary);
        document.documentElement.style.setProperty('--small-border-radius', theme.smallBorderRadius);
        document.documentElement.style.setProperty('--big-border-radius', theme.bigBorderRadius);
        document.documentElement.style.setProperty('--light-box-shadow', theme.lightBoxShadow);
        document.documentElement.style.setProperty('--input-padding', theme.inputPadding);
        document.documentElement.style.setProperty('--label-hight', theme.labelHeight);
        document.documentElement.style.setProperty('--footer-height', theme.footerHeight);
    }



    return { theme, setTheme };
}