import useGetApps from "./GetApps";
import "./apps.scss";

export function Apps() {
  const { senseApps, otherApps } = useGetApps();

  return (
    <div className="main-container">
      <div className="home-page instructions page flex column gap-1">
        <div className="flex column gap-1 oversight-container">
          {senseApps && senseApps.length > 0 && (
            <div>
              <div
                style={{
                  display: "flex",
                  gap: 4,
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
                className="flex gap-4 flex-col items-start"
              >
                <p
                  className="text-[1rem]"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <b>Our apps</b>
                </p>

                <div className="apps">
                  {senseApps?.map((app) => (
                    <div className="app">
                      <img src={app.icon} alt={app.name} />
                      <div>
                        <div className="install">
                          <h6>{app.name}</h6>
                          <a href={app.link} target="_blank">
                            <button
                              style={{
                                backgroundColor: "#fffff6",
                                border: "2px solid #dfdfdf",
                                borderRadius: 5,
                                padding: "0.2rem 0.5rem",
                                fontSize: "1rem",
                              }}
                            >
                              Install
                            </button>
                          </a>
                        </div>
                        <p>{app.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {otherApps && otherApps.length > 0 && (
            <div>
              <div
                style={{
                  display: "flex",
                  gap: 4,
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
                className="flex gap-4 flex-col items-start"
              >
                <p
                  className="text-[1rem]"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <b>Recommended apps</b>
                </p>

                <div className="apps">
                  {otherApps?.map((app) => (
                    <div className="app">
                      <img src={app.icon} alt={app.name} />
                      <div>
                        <div className="install">
                          <h6>{app.name}</h6>
                          <a href={app.link} target="_blank">
                            <button
                              style={{
                                backgroundColor: "#fffff6",
                                border: "2px solid #dfdfdf",
                                borderRadius: 5,
                                padding: "0.2rem 0.5rem",
                                fontSize: "1rem",
                              }}
                            >
                              Install
                            </button>
                          </a>
                        </div>
                        <p>{app.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
