
let currency = '$';

const serverDomain = 'https://utm-server.sense-apps.com'
// const serverDomain = 'https://95d0-2a0d-6fc2-67b0-5d00-fdd7-42c3-5561-880e.eu.ngrok.io'
// const serverDomain = 'http://localhost:3003'

function setCurrency(newCurrency) {
    currency = newCurrency
}
function getCurrency() {
    return currency
}

export const globalVariables = {
    serverDomain,
    setCurrency,
    getCurrency
}

