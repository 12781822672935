export function Progress({ number, sum }) {

    const precent = number / sum * 100

    return (
        <div className="progress-container">
            <span>{(Number.isNaN(precent) ? 0 : precent).toFixed(2)}%</span>
            <div style={{ width: precent + '%' }} className="progress" />
        </div>
    )
}