import httpService from "./httpService";

export const authService = {
    getToken,
    setToken,
    getShop,
    getPlan,
    clearToken,
    setShop,
    clearShop,
    checkPlan,
    updatePlan
}

const ENDPOINT = 'auth'

let currToken = '';
let currShop = '';
let currPlan = -1;

function getToken() {
    const storageToken = localStorage.getItem('token');
    if (!currToken && storageToken) currToken = storageToken
    return currToken
}

function getShop() {
    const storageShop = localStorage.getItem('shop');
    if (!currShop && storageShop) currShop = storageShop
    return currShop
}

function setToken(token) {
    currToken = token;
    localStorage.setItem('token', token)
}

function clearToken() {
    currToken = '';
    localStorage.setItem('token', '')
}

function clearShop() {
    currShop = '';
    localStorage.setItem('shop', '')
}

function setShop(shop) {
    currShop = shop;
    localStorage.setItem('shop', shop);
}

function getPlan() {
    return currPlan;
}

async function checkPlan() {
    const res = await httpService.get('shop/plan')
	console.log(await httpService.get('shop/plan'))
    currPlan = res.id;
    return res.id
}

async function updatePlan(planId) {
    const res = await httpService.put('shop/plan', { plan: planId })
    return res
}









