import { useRef, useState } from "react";
import OversightIcons from "./OversightIcons";


export function Tooltip({ children }) {
  const tooltipRef = useRef(null);
  const [tooltipAddedClasses, setTooltipAddedClasses] = useState("");

  const handleMouseEnter = (event) => {
    if (!tooltipRef.current) return;
    const rect = tooltipRef.current.getBoundingClientRect();
    const width = tooltipRef.current.offsetWidth;
    const height = tooltipRef.current.offsetHeight;
    // let addedClaseses = rect.y + height > window.innerHeight ? "top" : "bottom";
    let addedClaseses = rect.y + height > window.innerHeight ? "top" : "top";
    if (rect.x + width > window.innerWidth) addedClaseses += " right";
    else if (rect.x - width < 0) addedClaseses += " left";
    else addedClaseses += " center";
    setTooltipAddedClasses(addedClaseses);
  };

  const handleMouseLeave = (event) => {
    setTooltipAddedClasses("");
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`tooltip-container ${tooltipAddedClasses}`}
    >
      <OversightIcons icon={'tooltip'} />
      <div ref={tooltipRef} className="tooltip">
        {children}
      </div>
    </div>
  );
}
