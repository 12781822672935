import { useEffect, useState } from "react";
import axios from "axios";

const useGetApps = () => {
  const [apps, setApps] = useState(null);

  const getApps = async () => {
    try {
      //   const { data } = await axios.get("http://localhost:3038/apps", {
      const { data } = await axios.get(
        "https://master.sense-apps.com/api/apps/list",
        {
          withCredentials: true,
        }
      );

      setApps(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getApps();
  }, []);

  const senseApps = apps?.filter((app) => app.type === "SenseApps");
  const otherApps = apps?.filter((app) => app.type === "Other");

  return {
    apps: apps || null,
    senseApps,
    otherApps,
    isLoading: apps === null,
  };
};

export default useGetApps;
