import React, { useCallback, useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import OversightIcons from "../../../components/OversightIcons";
import { useDimensions } from "../../../hooks/useDimensions";
import { useRect } from "../../../hooks/useRect";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (index === 1) return;

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};
export function UTMSimlyPieChart({
    data,
    primaryColor,
    secondaryColor,
    targetRef,
    isHaveUtms,
    isError
}) {

    const [colors, setColors] = useState([primaryColor, secondaryColor]);
    const dimensions = useRect(targetRef)
    if (data.reduce((acc, item) => { return acc + item.value }, 0) > 0)
        return (
            <PieChart width={dimensions?.width - 25} height={350}>
                <Pie
                    data={data}
                    cx={(dimensions?.width - 25) / 2}
                    cy={175}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={100}
                    fill={primaryColor}
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={colors[index % colors.length]}
                        />
                    ))}
                </Pie>
            </PieChart>
        )
    else
        return (
            <div style={{ height: 350 }} className="placeholder">
                <OversightIcons icon='utm-pie-chart' />
                {
                    isError &&
                    <span className="error" >An error occured while trying to get data, please try again.</span>
                }
                {
                    !isError &&
                    <span>{isHaveUtms ? "We didn't find any UTM orders between the filtered dates" : "We didn't find any UTM orders on your shop"} </span>
                }
            </div>
        )

}
