import { useEffect, useState } from "react";
import httpService from "./httpService";

export const authService = {
	getToken,
	setToken,
	getShop,
	getPlan,
	clearToken,
	setShop,
	clearShop,
	checkPlan,
	updatePlan,
	getPlans,
};

const ENDPOINT = "auth";

let currToken = "";
let currShop = "";
let currPlan = -1;

function getToken() {
	const storageToken = localStorage.getItem("token");
	if (!currToken && storageToken) currToken = storageToken;
	return currToken;
}

function getShop() {
	const storageShop = localStorage.getItem("shop");
	if (!currShop && storageShop) currShop = storageShop;
	return currShop;
}

function setToken(token) {
	currToken = token;
	localStorage.setItem("token", token);
}

function clearToken() {
	currToken = "";
	localStorage.setItem("token", "");
}

function clearShop() {
	currShop = "";
	localStorage.setItem("shop", "");
}

function setShop(shop) {
	currShop = shop;
	localStorage.setItem("shop", shop);
}

function getPlan() {
	return currPlan;
}

async function checkPlan() {
	const res = await httpService.get("shop/plan");
	console.log({ res });

	currPlan = res || -1;
	return res || -1;
}

console.log({ currPlan });

async function updatePlan(planId) {
	const res = await httpService.put("shop/plan", { plan: planId });
	return res;
}

async function getPlans() {
	const res = await httpService.get("shop/plans");
	return res;
}

export const usePlans = () => {
	const [plans, setPlans] = useState([]);

	useEffect(() => {
		async function fetchData() {
			const plans = await getPlans();
			setPlans(plans);
		}

		fetchData();
	}, []);

	return plans;
};

// up_to_500_orders_month -> 500
export const getOrdersNumberFromFeature = (planOrSubscription) => {
	console.log({ planOrSubscription });
	const features = Object.entries(planOrSubscription.features);
	console.log({ features });
	const feature = features.find(([key]) => key.includes("up_to_"));
	console.log({ feature });
	if (!feature || !feature.length) return 30;

	const string = feature[0].replace("up_to_", "").replace("_orders_month", "");
	return Number.parseInt(string);
};
