import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RangeDatesPicker } from "../../components/RangeDatesPicker";
import { Spinner } from "../../components/Spinner";
import { useDebounce } from "../../hooks/useDebounce";
import { useThemeColors } from "../../hooks/useThemeColors";
import { authService } from "../../services/authService";
import { utmService } from "../../services/utmService";
import { Progress } from "./components/Progress";
import { UTMAreaMultiLineChart } from "./components/UTMAreaMultiLineChart";
import { UTMBarChart } from "./components/UTMBarChart";
import UTMPieChart from "./components/UTMPieChart";
import { UTMSimlyPieChart } from "./components/UTMSimlyPieChart";
import Modal from "../../components/Modal";

export function Dashboard({
  rangeDates,
  setRangeDates,
  ordersByDates,
  isPlanLimit,
  isPlanLimitShow,
  setIsPlanLimitShow,
  limit,
  isLoad,
  updateOrdersAndLimit,
  intervalCount,
  isHaveUtms,
  currency,
  isError,
}) {
  const [ordersUtms, setOrdersUtms] = useState([]);
  const { theme } = useThemeColors();
  const [utmPieChartData, setUtmPieChartData] = useState([]);
  const [utmBarChartData, setUtmBarChartData] = useState([]);
  const [utmAreaMultiLineChartData, setAreaMultiLineChartData] = useState([]);
  const [withUtmCount, setWithUtmCount] = useState(0);
  const navigate = useNavigate();

  const dateChange = () => {
    setOrdersUtms(ordersByDates);
    setUtmPieChartData(utmService.utmsBySource(ordersByDates));
    setUtmBarChartData(utmService.utmsByContent(ordersByDates));
    setAreaMultiLineChartData(
      utmService.utmsContentEarnByDates(ordersByDates, rangeDates)
    );
    setWithUtmCount(utmService.withUtmCount(ordersByDates));
  };

  useEffect(() => {
    dateChange();
  }, [rangeDates, ordersByDates]);

  const chartRef = useRef();
  const chartRef2 = useRef();
  const chartRef3 = useRef();
  const chartRef4 = useRef();

  // if (isLoad) return <div className="flex align-center justify-center loader-page">
  //     <div className='loader'><div></div><div></div><div></div><div></div></div>
  // </div>

  const [show, setShow] = useState(true);

  return (
    <div className="main-container">
      <div className="dashboard-page page flex column gap-1">
        <RangeDatesPicker
          dates={rangeDates}
          setDates={setRangeDates}
          updateOrdersAndLimit={updateOrdersAndLimit}
          isLoad={isLoad}
        />

        <Modal
          title="Alert"
          onClose={() => setIsPlanLimitShow(false)}
          show={isPlanLimitShow && limit}
        >
          <p>
            Please notice that your current plan is limited to {limit} orders per month,
            but your store has exceeded this limit.
          </p>
          <button
            onClick={() => {
              navigate("/plans");
            }}
            className="button primary"
          >
            Upgrade
          </button>
        </Modal>

        {
          //   isPlanLimit && <div className="limit-plan flex gap-1 align-center justify-space-between" >
          //       <span>
          //           Please notice that your current plan is limited to {limit} orders, but your store has exceeded this limit.
          //       </span>
          //       <button onClick={() => { navigate('/plans') }} className="button primary" >Upgrade</button>
          //   </div>
        }

        {isLoad ? (
          <div className="oversight-container">
            <Spinner isShowText={intervalCount > 25} />
          </div>
        ) : (
          <>
            <div className="flex gap-1">
              <div ref={chartRef} className="oversight-container flex-1">
                <span className="title">Orders by UTM source</span>
                {
                  // (isLoad) ? (
                  //     <Spinner
                  //         isShowText={intervalCount > 6}
                  //     />
                  // ) :
                  <>
                    <UTMPieChart
                      isError={isError}
                      data={utmPieChartData}
                      targetRef={chartRef}
                      primaryColor={theme.colorPrimary}
                      isHaveUtms={isHaveUtms}
                      currency={currency}
                    />
                  </>
                }
              </div>
              <div ref={chartRef4} className="oversight-container flex-1">
                <span className="title">UTM conversions from all orders</span>
                {/* {
                            (isLoad) ? (
                                <Spinner
                                    isShowText={intervalCount > 6}
                                />
                            ) : */}
                <UTMSimlyPieChart
                  data={[
                    { name: "with utm", value: withUtmCount },
                    {
                      name: "without utm",
                      value: ordersUtms.length - withUtmCount,
                    },
                  ]}
                  primaryColor={theme.colorPrimary}
                  secondaryColor={theme.colorSecondary}
                  isHaveUtms={isHaveUtms}
                  targetRef={chartRef4}
                  isError={isError}
                />
                {/* } */}
              </div>
            </div>
            <div ref={chartRef2} className="oversight-container flex-1">
              <span className="title">Orders by UTM content</span>
              {/* {
                        (isLoad) ? (
                            <Spinner
                                isShowText={intervalCount > 6}
                            />
                        ) : */}
              <UTMBarChart
                targetRef={chartRef2}
                primaryColor={theme.colorPrimary}
                secondaryColor={theme.colorSecondary}
                data={utmBarChartData}
                isHaveUtms={isHaveUtms}
                isError={isError}
              />
              {/* } */}
            </div>
          </>
        )}

        {/* <div ref={chartRef3} className="oversight-container flex-1" >
                    <span className="title">Total earning every day by content</span> */}
        {/* <UTMAreaMultiLineChart
                    targetRef={chartRef3}
                    primaryColor={theme.colorPrimary}
                    secondaryColor={theme.colorSecondary}
                    data={utmAreaMultiLineChartData}
                /> */}
        {/* </div> */}
      </div>
    </div>
  );
}
