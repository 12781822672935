import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/authService";
import { globalVariables } from "../services/globalVriables";

export default function AuthRoute() {

    const navigate = useNavigate()

    useEffect(() => {
        let currToken = authService.getToken()
        let search = window.location.search;
        let shop = new URLSearchParams(search).get('shop');
        if (currToken && !shop) navigate('/home')
        else {
            const paramsToken = new URLSearchParams(search).get('utmsession');
            if (shop) authService.setShop(shop);
            else shop = authService.getShop();
            if (paramsToken) {
                currToken = paramsToken
                authService.setToken(paramsToken)
                navigate('/home')
            }
            else if (!shop) {
                navigate('/no-shop')

            } else {
                window.location.href = `${globalVariables.serverDomain}/auth?shop=${shop}`
            }
        }
    }, [])

    return <div className="flex align-center justify-center loader-page">
        <div className='loader'><div></div><div></div><div></div><div></div></div>
    </div>
}

