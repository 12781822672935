import { useEffect, useState } from "react";
import { ratingService } from "../../services/ratingService";
import OversightIcons from "../OversightIcons";

const ReviewBox = () => {
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [closed, setClosed] = useState(false);

  const [shouldRate, setShouldRate] = useState(false);

  useEffect(() => {
    ratingService
      .checkRating()
      .then((response) => {
        console.log({ rate: response });
        setShouldRate(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  if (shouldRate === false) return null;

  const handleRating = (index) => {
    try {
      setRating(index);

      ratingService.updateRating(index);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    try {
      if (rating === 0) ratingService.updateRating(-1);

      setClosed(true);
    } catch (error) {
      console.error(error);
    }
  };

  const CloseButton = () => (
    <div
      style={{
        position: "absolute",
        top: "2px",
        right: "-10px",
        cursor: "pointer",
        width: "32px",
        height: "32px",
        fill: "#6B7280",
      }}
      onClick={handleClose}
    >
      <OversightIcons icon="close" />
    </div>
  );

  const handleBack = () => {
    setRating(0);
    setHoverRating(0);
  };

  const BackButton = () => (
    <div
      style={{
        position: "absolute",
        top: "2px",
        left: "10px",
        cursor: "pointer",
        width: "32px",
        height: "32px",
        fill: "#6B7280",
      }}
      onClick={handleBack}
    >
      <OversightIcons icon="arrow-left-2" />
    </div>
  );

  const Wrapper = ({ children }) => (
    <div className="fixed bottom-4 z-50">
      <div
        style={{
          boxShadow: "0 0 20px 0px #00000050",
          backgroundColor: "#fff",
          borderRadius: "8px",
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 40,
          paddingRight: 40,
          position: "fixed",
          bottom: 10,
          zIndex: 50,
          marginLeft: "50%",
          transform: "translateX(-50%)",
          alignContent: "center",
          height: 110,
          width: 800,
        }}
      >
        {children}
      </div>
    </div>
  );

  if (closed) return null;

  if (rating) {
    switch (rating) {
      case 1:
      case 2:
        return (
          <Wrapper>
            <CloseButton />
            <BackButton />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 6,
              }}
            >
              <p
                style={{
                  fontSize: "0.875rem",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Oh no! We're sorry to hear we didn't succeed with you.
                <br />
                Is there a chance you can share with us what bothered you? We
                would appreciate it very much and will do everything to improve!
                💙
              </p>
              <a href="https://sense-apps.com/request/" target="_blank">
                <button className="button">Submit Feedback</button>
              </a>
            </div>
          </Wrapper>
        );

      case 3:
      case 4:
        return (
          <Wrapper>
            <CloseButton />
            <BackButton />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 6,
              }}
            >
              <p
                style={{
                  fontSize: "0.875rem",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                We understand - there's always room for improvement. We'd love
                to get more detailed feedback from you.
                <br />
                We would appreciate it very much and will do everything to
              </p>
              <a href="https://sense-apps.com/request/" target="_blank">
                <button className="button" primary>
                  Submit Feedback
                </button>
              </a>
            </div>
          </Wrapper>
        );

      case 5:
        return (
          <Wrapper>
            <CloseButton />
            <BackButton />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 6,
              }}
            >
              <p
                style={{
                  fontSize: "0.875rem",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Wow, we really appreciate it!
                <br />
                Is there a chance for a review in the Shopify store as well? 💙
              </p>
              <a
                href="https://apps.shopify.com/sense-utm#modal-show=WriteReviewModal"
                target="_blank"
              >
                <button className="button">Review</button>
              </a>
            </div>
          </Wrapper>
        );
    }
  }

  return (
    <Wrapper>
      <CloseButton />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 6,
        }}
      >
        <h3
          style={{
            fontSize: "1.125rem",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          Enjoying Sense UTM?
        </h3>
        <p
          style={{
            fontSize: "0.875rem",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Based on your experience with the app, how likely are you to recommend
          the Sense UTM app?
        </p>
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
          }}
          onMouseLeave={() => setHoverRating(0)}
        >
          {[1, 2, 3, 4, 5].map((_, index) => (
            <Star
              key={index}
              active={index < rating || index < hoverRating}
              onClick={() => handleRating(index + 1)}
              onMouseEnter={() => setHoverRating(index + 1)}
            />
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

const Star = ({ active, onClick, onMouseEnter }) => {
  return (
    <span onClick={onClick} onMouseEnter={onMouseEnter}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        style={{
          fill: active ? "#F59E0B" : "none",
          stroke: active ? "#F59E0B" : "#D1D5DB",
          strokeWidth: "2px",
          width: "24px",
          height: "24px",
          cursor: "pointer",
          animationDuration: "200ms",
        }}
      >
        <path
          fill-rule="evenodd"
          d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </span>
  );
};

export default ReviewBox;
