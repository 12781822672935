import { useDimensions } from "../../../hooks/useDimensions"
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";
import { useEffect } from "react";

export function Item({
    data,
    targetRef,
    primaryColor
}) {
    const colors = [
        primaryColor,
        '#2980B9',
        '#1ABC9C',
        '#F1C40F',
        '#DC7633',
        '#DC7633',
        '#5D6D7E',
        '#5D6D7E',
    ]

    useEffect(() => {
    }, [targetRef])


    const dimensions = {
        width: targetRef?.offsetWidth,
        height: targetRef?.offsetHeight
    }
    return (
        <div>
            <LineChart
                width={dimensions.width - 30}
                height={200}
                data={
                    data.map(item => {
                        const [year, month, day] = item.date.split('-')
                        return {
                            ...item,
                            date: `${month}/${day}`
                        }
                    })
                }
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}
            >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip
                />
                {/* <Legend
                /> */}

                {
                    Object.keys(data[0] || {})
                        .filter(key => key !== "date")
                        .map((key, idx) => (
                            <Line
                                key={idx}
                                type="monotone"
                                dataKey={key}
                                strokeOpacity={1}
                                stroke={colors[idx % 9]}
                                activeDot={{ r: 4 }}
                            />
                        ))
                }
            </LineChart>
        </div>
    )

}