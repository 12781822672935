import { useEffect, useLayoutEffect, useRef, useState } from "react";

export function useDimensions(targetRef) {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            });
        }
    }, []);



    const resizeObserver = useRef();

    useEffect(() => {
        if (!targetRef.current) return;
        const element = targetRef.current;
        if (element === null) return;
        const currRect = element.getBoundingClientRect();
        setDimensions({ width: currRect.width, height: currRect.height })

        resizeObserver.current = new ResizeObserver(() => {
            if (!element) return;
            const currRect = element.getBoundingClientRect();
            setDimensions({ width: currRect.width, height: currRect.height })

        });
        resizeObserver.current.observe(element);
        return () => {
            if (resizeObserver.current) resizeObserver.current.disconnect();
        };
    }, [targetRef.current]);

    return dimensions
}