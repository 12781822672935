import logo from "../../assets/images/utm-text.png"

export function NoShop() {
    return (
        <div className="main-container" >

            <div
                className="no-shop-page page flex column align-center justify-center gap-1"
            >
                <img src={logo} alt="logo" />
                <span>To view this app, please log in from your Shopify store management</span>
                <a href="https://shopify.com/admin">Shopify</a>
            </div>
        </div>
    )
}