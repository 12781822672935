export const utilService = {
    formatDate,
    getRandomInt,
    filterNotLettersOrNumbers,
    getRandomDouble,
    notResevedCharacters,
    startMonth,
    endMonth,
    genId
}

function formatDate(date) {

    const formatted = date.toLocaleDateString({},
        { month: "long", day: "2-digit", year: "numeric" }
    )
    return formatted
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function filterNotLettersOrNumbers(string) {
    return string.split('').map(char => char.replace(/[^\p{L}0-9]/u, '')).join('');
}

function notResevedCharacters(string) {
    const revertChars = [";", "/", "?", ":", "@", "&", "=", "+", "$", ","]
    return string.split('').filter(char => !revertChars.some(revertChar => revertChar === char)).join('');
}

function getRandomDouble(min, max) {
    return Math.random() * (max - min) + min;
}

function startMonth(date) {
    return new Date(
        date.getFullYear(),
        date.getMonth(),
    );
}

function endMonth(date) {
    return new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
    );
}

function genId() {
    return Math.random().toString(36).substr(2, 9);
}