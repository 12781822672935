// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apps {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 2rem;
}
.apps .app {
  border: 1px solid #dfdfdf;
  padding: 0.8rem;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  width: 100%;
}
.apps .app img {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 10px;
}
.apps .app .install {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.apps .app div {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.apps .app div h6 {
  font-weight: 800;
  font-size: 1.2rem;
}
.apps .app div span {
  font-size: 0.9rem !important;
}
.apps .app div p {
  font-weight: 400;
  font-size: 1rem;
  text-align: start !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Apps/apps.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,8BAAA;EACA,cAAA;EACA,mBAAA;AACD;AACC;EACC,yBAAA;EACA,eAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;AACF;AACE;EACC,cAAA;EACA,aAAA;EACA,mBAAA;AACH;AAEE;EACC,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;AAAH;AAGE;EACC,aAAA;EACA,sBAAA;EACA,WAAA;AADH;AAGG;EACC,gBAAA;EACA,iBAAA;AADJ;AAIG;EACC,4BAAA;AAFJ;AAKG;EACC,gBAAA;EACA,eAAA;EACA,4BAAA;AAHJ","sourcesContent":[".apps {\n\tdisplay: grid;\n\tgrid-template-columns: 1fr 1fr;\n\tgrid-gap: 1rem;\n\tmargin-bottom: 2rem;\n\n\t& .app {\n\t\tborder: 1px solid #dfdfdf;\n\t\tpadding: 0.8rem;\n\t\tdisplay: flex;\n\t\tgap: 1rem;\n\t\tflex-direction: row;\n\t\talign-items: center;\n\t\tborder-radius: 5px;\n\t\twidth: 100%;\n\n\t\t& img {\n\t\t\theight: 3.5rem;\n\t\t\twidth: 3.5rem;\n\t\t\tborder-radius: 10px;\n\t\t}\n\n\t\t& .install {\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: row;\n\t\t\tjustify-content: space-between;\n\t\t\twidth: 100%;\n\t\t}\n\n\t\t& div {\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\t\t\twidth: 100%;\n\n\t\t\t& h6 {\n\t\t\t\tfont-weight: 800;\n\t\t\t\tfont-size: 1.2rem;\n\t\t\t}\n\n\t\t\t& span {\n\t\t\t\tfont-size: 0.9rem !important;\n\t\t\t}\n\n\t\t\t& p {\n\t\t\t\tfont-weight: 400;\n\t\t\t\tfont-size: 1rem;\n\t\t\t\ttext-align: start !important;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
