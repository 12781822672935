import { useCallback, useEffect, useRef, useState } from "react"

export function useRect(element = null) {

    const [rect, setRect] = useState(null);
    const resizeObserver = useRef();

    useEffect(() => {
        if (!element && !element.current) return;
        const currRect = element.current ? element.current.getBoundingClientRect() : element.getBoundingClientRect();
        setRect(currRect)

        resizeObserver.current = new ResizeObserver(() => {
            if (!element && !element.current) return;
            const currRect = element.current ? element.current.getBoundingClientRect() : element.getBoundingClientRect();
            setRect(currRect)
        });
        resizeObserver.current.observe(element.current ? element.current : element);
        return () => resizeObserver.current.disconnect(); // clean up 
    }, [element])




    return rect
}