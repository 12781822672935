import { usePlans } from "../../services/authService";

const BlackFridayBox = () => {
  const plans = usePlans();
  console.log({ plans });
  const isBlackFridayUser = plans?.some((p) => p.name.includes("Black Friday"));

  return (
    isBlackFridayUser && (
      <div style={{ marginBottom: 16, width: "100%" }}>
        <div
          style={{
            backgroundColor: "#000",
            color: "#fff",
            borderRadius: "10px",
            padding: "20px",
            textAlign: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
			  flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 8,
            }}
          >
            <b
              style={{
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              BLACK FRIDAY SALE
            </b>
            <p
              style={{
                fontSize: "16px",
              }}
            >
              Our biggest sale of the year - 25% off all yearly plans!
            </p>
          </div>
        </div>
      </div>
    )
  );
};

export default BlackFridayBox;
