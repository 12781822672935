import { NavLink, useLocation, useNavigate } from "react-router-dom";
import OversightIcons from "./OversightIcons";
import { RangeDatesPicker } from "./RangeDatesPicker";
import sencseLogo from "../assets/images/sense-logo.png";
import utmLogo from "../assets/images/UTM.png";
import { useEffect } from "react";
import { Input } from "./Input";
import { authService } from "../services/authService";

export function Navbar({ }) {
	const location = useLocation();
	const navigae = useNavigate();

	const onLogout = () => {
		authService.clearShop();
		authService.clearToken();
		navigae('/no-shop')

	}


	if (location.pathname === '/') return <></>
	if (location.pathname === '/no-shop') return <></>
	return (
		<div className='navbar-wrapper' >
			{/* <a className='whatsapp' href='https://wa.me/message/42UCR4NZGVWOL1' target="_blank" >
                <OversightIcons icon={"whatsapp"} />
            </a> */}
			<div className='main-container' >

				<nav className="navbar-container">
					<div className='flex align-center gap-1' >
						<img
							src={utmLogo}
							alt="sense-app-logo"
						/>

						<span className='shop-name' >
							{authService.getShop().split('.')[0]}
						</span>

					</div>
					<ul className="list" >
						<li>
							<NavLink to="/dashboard">
								<div className='icon' ><OversightIcons icon="charts" /> </div>
								<div className='text' >Dashboard</div>
							</NavLink>
						</li>
						<li>
							<NavLink to="/home">
								<div className='icon' ><OversightIcons icon='home' /> </div>
								<div className='text' >Home</div>
							</NavLink>
						</li>
						<li>
							<NavLink to="/generation">
								<div className='icon' ><OversightIcons icon="link" /> </div>
								<div className='text' >Generation</div>
							</NavLink>
						</li>
						{
							(location.pathname !== '/plans' && location.pathname !== '/instructions' && location.pathname !== '/orders' && location.pathname !== '/apps') &&
							< div className='indicator' />
						}
					</ul>
					<div className='sense-app' >
						<button onClick={onLogout} className='button primary'>
							Logout
						</button>
						<NavLink to="/plans">
							<div className='text' >Plans</div>
						</NavLink>
						<NavLink to="/instructions">
							<div className='text' >Instructions</div>
						</NavLink>
						<a target="_blank" href="https://sense-apps.com/en/support/">
							<div className='text' >Support</div>
						</a>
						<NavLink to="/apps">
							<div className='text' >Our Apps</div>
						</NavLink>
						{/* <a>
                            Support
                        </a> */}
						{/* <div >
                            <Input
                                placeholder='Current Shop'
                                value={authService.getShop().split('.')[0]}
                                isDisabled={true}
                            />
                        </div> */}
					</div>
				</nav>
			</div>
		</div>
	);
}
