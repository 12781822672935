import React, { PureComponent } from "react";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
import OversightIcons from "../../../components/OversightIcons";
import { useDimensions } from "../../../hooks/useDimensions";
import { useRect } from "../../../hooks/useRect";
import { globalVariables } from "../../../services/globalVriables";

// const data = [
//     {
//         name: 'Page G',
//         uv: 3490,
//         pv: 4300,
//         amt: 2100,
//     },
// ];

export function UTMBarChart({
	targetRef,
	primaryColor,
	secondaryColor,
	data,
	isHaveUtms,
	isError,
}) {
	const getTotalByContent = (content) => {
		const item = data.find((dataItem) => dataItem.content === content);
		const currency = globalVariables.getCurrency();
		return (
			currency +
			Number(item.total).toLocaleString("he-IL", {
				maximumFractionDigits: 2,
			})
		);
	};

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className="custom-tooltip">
					<p className="content">{label}</p>
					<p className="count">
						{payload[0].value.toLocaleString("he-IL")} Orders
					</p>
					<p className="total">{getTotalByContent(label)}</p>
				</div>
			);
		}

		return null;
	};

	const dimensions = useRect(targetRef);
	if (data.length > 0)
		return (
			<BarChart
				width={dimensions?.width - 24}
				height={300}
				data={data.sort((a, b) => b.count - a.count)}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
				barSize={10}
			>
				<XAxis
					dataKey="content"
					tick={false}
					scale="point"
					padding={{ left: 10, right: 10 }}
				/>
				<YAxis />
				<Tooltip content={<CustomTooltip />} />
				{/* <Legend /> */}
				<CartesianGrid strokeDasharray="3 3" />
				<Bar
					dataKey="count"
					fill={primaryColor}
					background={{ fill: "#0000" }}
				/>
			</BarChart>
		);
	return (
		<div style={{ height: 300 }} className="placeholder">
			<OversightIcons icon="utm-bar-chart" />
			{isError && (
				<span className="error">
					An error occured while trying to get data, please try again.
				</span>
			)}
			{!isError && (
				<span>
					We didn't find any UTM orders between the filtered dates.
				</span>
			)}
		</div>
	);
}
