import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/pro-thin-svg-icons'
import * as solid from '@fortawesome/pro-solid-svg-icons'
import { faWhatsapp, faShopify } from '@fortawesome/free-brands-svg-icons'



export default function OversightIcons({ icon }) {
    switch (icon) {
        case 'add':
            return <FontAwesomeIcon icon={fa.faAdd} />
        case 'table-columns':
            return <FontAwesomeIcon icon={fa.faTableColumns} />
        case 'mini-menu':
            return <FontAwesomeIcon icon={fa.faEllipsisStrokeVertical} />
        case 'home':
            return <FontAwesomeIcon icon={fa.faHouseChimney} />
        case 'template':
            return <FontAwesomeIcon icon={fa.faMemo} />
        case 'arrow-left':
            return <FontAwesomeIcon icon={solid.faCaretLeft} />
        case 'whatsapp':
            return <FontAwesomeIcon icon={faWhatsapp} />
        case 'distribution':
            return <FontAwesomeIcon icon={fa.faBullhorn} />
        case 'blacklist':
            return <FontAwesomeIcon icon={fa.faBookSkull} />
        case 'error':
            return <FontAwesomeIcon icon={fa.faShield} />
        case 'warning':
            return <FontAwesomeIcon icon={fa.faTriangleExclamation} />
        case 'orders':
            return <FontAwesomeIcon icon={fa.faBox} />
        case 'shopify':
            return <FontAwesomeIcon icon={faShopify} />
        case 'info':
            return <FontAwesomeIcon icon={fa.faCircleInfo} />
        case 'success':
            return <FontAwesomeIcon icon={fa.faSquareCheck} />
        case 'law':
            return <FontAwesomeIcon icon={fa.faMessageSmile} />
        case 'keyboard':
            return <FontAwesomeIcon icon={fa.faKeyboard} />
        case 'emoji':
            return <FontAwesomeIcon icon={fa.faFaceGrinWide} />
        case 'send':
            return <FontAwesomeIcon icon={fa.faPaperPlaneTop} />
        case 'clock':
            return <FontAwesomeIcon icon={fa.faClock} />
        case 'search':
            return <FontAwesomeIcon icon={fa.faMagnifyingGlass} />
        case 'animal':
            return <FontAwesomeIcon icon={fa.faPaw} />
        case 'drink':
            return <FontAwesomeIcon icon={fa.faMugHot} />
        case 'ball':
            return <FontAwesomeIcon icon={fa.faFutbol} />
        case 'car':
            return <FontAwesomeIcon icon={fa.faCar} />
        case 'lightbulb':
            return <FontAwesomeIcon icon={fa.faLightbulb} />
        case 'symbols':
            return <FontAwesomeIcon icon={fa.faSymbols} />
        case 'attechment':
            return <FontAwesomeIcon icon={fa.faPaperclip} />
        case 'note':
            return <FontAwesomeIcon icon={fa.faNote} />
        case 'add-user':
            return <FontAwesomeIcon icon={fa.faUserPlus} />
        case 'ban':
            return <FontAwesomeIcon icon={fa.faBan} />
        case 'close':
            return <FontAwesomeIcon icon={fa.faXmark} />
        case 'excel':
            return <FontAwesomeIcon icon={fa.faFileExcel} />
        case 'eye':
            return <FontAwesomeIcon icon={fa.faEye} />
        case 'minus':
            return <FontAwesomeIcon icon={fa.faMinus} />
        case 'img':
            return <FontAwesomeIcon icon={fa.faImage} />
        case 'labels':
            return <FontAwesomeIcon icon={fa.faTags} />
        case 'dowloand':
            return <FontAwesomeIcon icon={fa.faCircleDown} />
        case 'calendar':
            return <FontAwesomeIcon icon={fa.faCalendar} />
        case 'charts':
            return <FontAwesomeIcon icon={fa.faChartPie} />
        case 'link':
            return <FontAwesomeIcon icon={fa.faLink} />
        case 'utm-pie-chart':
            return <FontAwesomeIcon icon={fa.faChartPie} />
        case 'utm-bar-chart':
            return <FontAwesomeIcon icon={fa.faChartColumn} />
        case 'utm-area-chart':
            return <FontAwesomeIcon icon={fa.faChartLine} />
        case 'copy':
            return <FontAwesomeIcon icon={fa.faCopy} />
        case 'arrow-up':
            return <FontAwesomeIcon icon={fa.faAngleUp} />
        case 'arrow-down':
            return <FontAwesomeIcon icon={fa.faAngleDown} />
        case 'arrow-left-2':
            return <FontAwesomeIcon icon={fa.faArrowLeft} />
        case 'refresh':
            return <FontAwesomeIcon icon={solid.faArrowsRotate} />
        case 'info':
            return <FontAwesomeIcon icon={fa.faInfoCircle} />
        case 'tooltip':
            return <FontAwesomeIcon icon={solid.faQuestion} />
        default:
            return <FontAwesomeIcon icon={fa.faUserPlus} />
            break;
    }
}