import logo from "../../assets/images/utm-text.png";
import { Accordion } from "../../components/Accordion";

export function Instructions() {
  return (
    <div className="main-container">
      <div className="home-page instructions page flex column gap-1">
        <div className="flex column gap-1 oversight-container">
          <span className="title">SenseApps UTM Analytics</span>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/bJWXeF0qRys"
            style={{ margin: "0 auto" }}
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <div className="flex list-and-image">
            <ol>
              <li>
                <div className="flex column gap-small">
                  Install the Sense UTM app from the Shopify app store.
                </div>
              </li>
              <li>
                <div className="flex column gap-small">
                  Once an order has been placed with UTM tags, the app will load
                  this order to the statistics (this might take up to 24 hours).
                </div>
              </li>
              <li>
                <div className="flex column gap-small">
                  Easily view statistics and order data from the app dashboard.
                </div>
              </li>
            </ol>
            <img src={logo} />
          </div>
          <div className="flex column gap-small">
            We wish you good luck! If any assistance is required, feel free to
            contact us through{" "}
            <a
              href="https://wa.me/message/42UCR4NZGVWOL1"
              style={{ display: "contents" }}
              target="_blank"
            >
              our WhatsApp
            </a>
            🙂
          </div>
        </div>

        <Accordion
          items={[
            {
              title: "What is a UTM code in Shopify?",
              content:
                "A UTM code is a simple code that you can add to a custom URL in order to track a source, medium, and campaign name. This helps you track the effectiveness of your advertising and marketing efforts in Shopify.",
            },
            {
              title: "Why use UTM codes in Shopify?",
              content:
                "UTM codes in Shopify allow you to track the success of your campaigns and see where your website traffic is coming from. This information is useful for optimizing your marketing efforts and making data-driven decisions.",
            },
            {
              title: "How do I add UTM codes to my Shopify URLs?",
              content:
                "You can add UTM codes to your Shopify URLs by adding the relevant parameters (such as utm_source, utm_medium, and utm_campaign) to the end of your URL. You can use a tool like the Google Analytics Campaign URL Builder to generate your UTM codes.",
            },
            {
              title: "Can I use UTM codes in my Shopify emails?",
              content:
                "Yes, you can use UTM codes in your Shopify emails. Simply add the UTM-coded URL to any links in your email campaigns, and you'll be able to track the success of your email campaigns in Google Analytics.",
            },
            {
              title: "How do I view my UTM code data in Shopify?",
              content:
                "You can view your UTM code data in Shopify by connecting your Shopify store to Google Analytics and viewing the Campaigns report in Google Analytics. This will show you data on the number of clicks, sessions, and conversions generated by each UTM-coded URL.",
            },
            {
              title: "What is the utm_source parameter in a UTM code?",
              content:
                "The utm_source parameter in a UTM code is used to identify the source of your traffic, such as Google, Facebook, or an email newsletter. This information helps you track the effectiveness of your marketing campaigns and make data-driven decisions.",
            },
            {
              title: "What is the utm_medium parameter in a UTM code?",
              content:
                "The utm_medium parameter in a UTM code is used to identify the medium of your traffic, such as email, social media, or pay-per-click advertising. This information helps you track the effectiveness of your marketing channels and make data-driven decisions.",
            },
            {
              title: "What is the utm_campaign parameter in a UTM code?",
              content:
                "The utm_campaign parameter in a UTM code is used to identify the specific campaign or initiative that your traffic is associated with. This information helps you track the success of your campaigns and make data-driven decisions.",
            },
            {
              title: "Can I use UTM codes on my Shopify checkout pages?",
              content:
                "Yes, you can use UTM codes on your Shopify checkout pages. Simply add the UTM-coded URL to any links that lead to your checkout pages, and you'll be able to track the success of your campaigns in Google Analytics.",
            },
          ]}
        />
      </div>
    </div>
  );
}
