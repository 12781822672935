import { useState } from "react";
import { Input } from "../../components/Input";
import OversightIcons from "../../components/OversightIcons";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import useStateWithValidation from "../../hooks/useStateWithValidation";
import { utilService } from "../../services/utilService";
import { syncValidation } from "../../validation/syncValidation";

export function Generation() {

    const [url, setUrl, isUrlValid] = useStateWithValidation(syncValidation.isValidUrl, '')
    const [source, setSource] = useState('')
    const [medium, setMedium] = useState('')
    const [name, setName] = useState('')
    const [term, setTerm] = useState('')
    const [content, setContent] = useState('')
    const [copyToClipboard, { value, success }] = useCopyToClipboard()
    const [isExplanOpen, setIsExplaneOpen] = useState(true);





    const createUtm = () => {
        if (!isUrlValid || !source || !medium || !name) return '';
        let utm = (!url.includes('http://') && !url.includes('https://')) ? 'http://' + url : url;
        if (source) {
            utm += (!utm.includes('?')) ? `?utm_source=${source}` : `&utm_source=${source}`
        }
        if (name) {
            utm += (!utm.includes('?')) ? `?utm_campaign=${name}` : `&utm_campaign=${name}`
        }
        if (medium) {
            utm += (!utm.includes('?')) ? `?utm_medium=${medium}` : `&utm_medium=${medium}`
        }
        if (content) {
            utm += (!utm.includes('?')) ? `?utm_content=${content}` : `&utm_content=${content}`
        }
        if (term) {
            utm += (!utm.includes('?')) ? `?utm_term=${term}` : `&utm_term=${term}`
        }
        return utm
    }

    const errorMsg = () => {
        if (!isUrlValid) return 'The URL is invalid'
        if (!source) return 'No source'
        if (!medium) return 'No medium'
        if (!name) return 'No campaign'
    }

    const utm = createUtm();
    const erorMessage = errorMsg();


    return (
        <div className="main-container" >
            <div
                className="home-page page flex column gap-1"
            >
                {
                    isExplanOpen &&
                    <div className="explan flex align-center" >
                        <div>
                            <OversightIcons icon={'info'} />
                        </div>
                        <span className="flex column">
                            <span>On this page, you can generate your UTM link to analyze your data better,</span>
                            follow the instructions below the inputs and start advertising your store the better way!
                        </span>
                        <button onClick={() => { setIsExplaneOpen(false) }} className="close" >✕</button>

                    </div>
                }
                <form className="flex column gap-1 oversight-container" >
                    <div className="flex column gap-small" >

                        <Input
                            value={url}
                            onChange={setUrl}
                            placeholder="Website URL"
                        />
                        <small>The full website URL (e.g. <strong>https://www.example.com</strong>).</small>
                    </div>
                    <div className="flex column gap-small" >

                        <Input
                            value={source}
                            onChange={(v) => setSource(utilService.notResevedCharacters(v))}
                            placeholder="UTM source*"
                        />
                        <small>The referrer (e.g. <strong>google, facebook</strong>).</small>
                    </div>
                    <div className="flex column gap-small" >
                        <Input
                            value={name}
                            onChange={v => setName(utilService.notResevedCharacters(v))}
                            placeholder="UTM campaign*"
                        />
                        <small>Product promo code, or slogan (e.g. <strong>black_friday</strong>).</small>
                    </div>
                    <div className="flex column gap-small" >
                        <Input
                            value={medium}
                            onChange={v => setMedium(utilService.notResevedCharacters(v))}
                            placeholder="UTM medium*"
                        />
                        <small>Marketing medium (e.g. <strong>cpc, banner, email</strong>).</small>
                    </div>
                    <div className="flex column gap-small" >
                        <Input
                            value={term}
                            onChange={v => setTerm(utilService.notResevedCharacters(v))}
                            placeholder="UTM term"
                        />
                        <small>Identify the paid keywords.</small>
                    </div>
                    <div className="flex column gap-small" >
                        <Input
                            value={content}
                            onChange={v => setContent(utilService.notResevedCharacters(v))}
                            placeholder="UTM content"
                        />
                        <small>Use to differentiate ads.</small>
                    </div>

                    <div className='input-container flex gap-1'>
                        <input
                            value={erorMessage ? erorMessage : utm}
                            disabled={true}
                        />

                        <button

                            onClick={(ev) => {
                                ev.preventDefault();
                                copyToClipboard(utm)
                            }}
                            className={`button ${utm === value && success ? 'secondary' : 'primary'}`}
                            disabled={erorMessage}
                        >
                            {
                                utm === value && success ?
                                    <span>
                                        ✓ Copied!
                                    </span>
                                    :
                                    <>
                                        <OversightIcons icon='copy' />
                                        <span>Copy</span>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}