export const syncValidation = {
    isValidUrl
}

function isValidUrl(string) {
    if (!string.includes('http://') && !string.includes('https://')) string = 'http://' + string;
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}