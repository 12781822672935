import Axios from 'axios';
import { authService } from './authService';
import { globalVariables } from './globalVriables';

const BASE_URL = `${globalVariables.serverDomain}/`

var axios = Axios.create({
    withCredentials: false
});

axios.interceptors.request.use(function (config) {
    const token = authService.getToken();
    config.headers.utmsession = token;
    return config;
});

export default {
    get(endpoint, data) {
        return ajax(endpoint, 'GET', data)
    },
    post(endpoint, data) {
        return ajax(endpoint, 'POST', data)
    },
    put(endpoint, data) {
        return ajax(endpoint, 'PUT', data)
    },
    delete(endpoint, data) {
        return ajax(endpoint, 'DELETE', data)
    },
}

async function ajax(endpoint, method = 'get', data = null) {
    try {
        const res = await axios({
            url: `${BASE_URL}${endpoint}`,
            method,
            data
        })
        return res.data;
    } catch (err) {
        console.log(`Had Issues ${method}ing to the backend, endpoint: ${endpoint}, with data: ${data}`);
        console.dir(err);
        if (err.response && err.response.status === 401) {
            const shop = authService.getShop();
            authService.clearToken();
            authService.clearShop();
            // window.location.href = `${globalVariables.serverDomain}/auth?shop=${shop}`
        }
        throw err;
    }
}

